import { SituacaoAgendamento } from "../enums/EnumSituacaoAgendamento";
import Pacientes from "../pacientes/Pacientes";

export default class AgendamentoConsulta{
    public pacienteId: number;
    public paciente: Pacientes;
    public telefone: string;
    public email: string;
    public convenioId!: number;
    public planoId?: number;
    public tipoConsultaId?: number;
    public retorno: boolean;
    public horaAtendimento: string;
    public horaChegada: string;


    constructor() {
        this.pacienteId = 0;
        this.paciente = new Pacientes();
        this.telefone = '';
        this.email = '';
        this.retorno = false;
        this.horaAtendimento = '';
        this.horaChegada = '0001-01-01T00:00:00';
    }
}      
       