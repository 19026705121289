export default class Modelos{
    public id: number;
    public tipoModelo: string;
    public nome: string;
    public descricao: string;

    constructor(){
        this.id = 0;
        this.tipoModelo = "";
        this.nome = "";
        this.descricao = "";
    }
}