import { DiaSemana } from "../enums/EnumDiaSemana";
import Agenda from "./Agenda";

export default class HorarioAgenda {
    public id:number;
    public tenantId?:number;
    public agendaId:number;
    public agenda: Agenda;
    public diaSemana: DiaSemana;
    public horaInicio:string;
    public horaFim:string;
    public intervalo:string;

    
    
    constructor(){
        this.id = 0;
        this.agendaId = 0;
        this.agenda = new Agenda();   
        this.diaSemana = 0;
        this.horaInicio;
        this.horaFim;
        this.intervalo;
    }
}