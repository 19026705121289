import Pacientes from "../pacientes/Pacientes";
import Usuario from "../usuario/Usuario";

export default class Encaminhamento {
    public id: number;
    public descricao: string;

    constructor() {
        this.id = 0;
        this.descricao = '';
    }
}
