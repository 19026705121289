import Pacientes from '@/models/pacientes/Pacientes';
import  http  from './ApiConfig';
import { Service } from './Service';

export class PacientesService extends Service {

    constructor(){
        super('pacientes');
    }

    public GetAllPaginado(dataNascimento: string, nomeId: string, cpf: string, limit: number, page: number) {
        return http.get(`${this._nomeControle}?dataNascimento=${dataNascimento}&nomeId=${nomeId}&cpf=${cpf}&limit=${limit}&page=${page}`, this.GetHeader());
    }

    public BuscarPaciente(q: string) {
        return http.get(`${this._nomeControle}?q=${q}`, this.GetHeader());
    }    

    public AtivarPaciente(pacienteId: number) {
        return http.patch(`${this._nomeControle}/${pacienteId}/ativar`, this.GetHeader());
    }  
    
    public InativarPaciente(pacienteId: number) {
        return http.patch(`${this._nomeControle}/${pacienteId}/inativar`, this.GetHeader());
    }  
}
