import Pacientes from "../pacientes/Pacientes";
import Convenio from "./Convenio";
import Plano from "./Plano";

export default class PacientesConvenio{    
    public id: number;
    public convenioId?: number;   
    public planoId?: number;    
    public numeroRegistro: string;
    public vencimento: string;

    constructor(obj?: any){    
        if (obj){
            this.id = obj.id;           
            this.convenioId = obj.convenioId;        
            this.planoId = obj.planoId;       
            this.numeroRegistro = obj.numeroRegistro;        
            this.vencimento = obj.vencimento;
        }
        else {
            this.id = 0;           
            this.convenioId = 0;        
            this.planoId = 0;       
            this.numeroRegistro = '';        
            this.vencimento = '0001-01-01T00:00:00';    
        }
    }

}
