import  http  from './ApiConfig';
import { Service } from './Service';

export class TenantService extends Service{

    constructor() {
        super('tenant');
    }

    public GetAllClinicasPaginado(nomeDominio: string, nomeMedico: string, email:string, documento:string, limit: number, page: number) {
        return http.get(`${this._nomeControle}?nomeDominio=${nomeDominio}&nomeMedico=${nomeMedico}&email=${email}&documento=${documento}&limit=${limit}&page=${page}`, this.GetHeader());
    }

    public ValidaDocumento(documento: string,) {
        return http.get(`${this._nomeControle}/valida-documento/${documento}`, this.GetHeader());
    }
    public GetTenantDoUsuario() {
        return http.get(`${this._nomeControle}/tenant-do-usuario`, this.GetHeader());
    }
}