export interface IAuthUserModel {
  id: string;
  name: string;
  sobrenome: string
  userName: string;
  profilePicture: string;
  role: string;
  permissions: Array<string>;
  empresaId: number;
  tenantId: string;
  razaoSocial: string;
  versaoTeste: string;
  dataInadimplencia: string;
}


export default class AuthUserModel {
  public id: string;

  public name: string;

  public sobrenome: string;

  public userName: string;

  public profilePicture: string;

  public role: string;

  public permissions: Array<string>;

  public empresaId: number;

  public tenantId: string;

  public razaoSocial: string;

  public versaoTeste: string;

  public dataInadimplencia: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.sobrenome  ='';
    this.userName = '';
    this.profilePicture = '';
    this.role = '';
    this.permissions = [];
    this.empresaId = 0;
    this.tenantId = '';
    this.razaoSocial = '';
    this.versaoTeste = '';
    this.dataInadimplencia = '';
  }
}
