<template>
    <div>
        <v-dialog v-model="modalAberto" transition="dialog-bottom-transition" max-width="900">
            <v-card :loading="realizandoRemocao ? 'success' : null" :disabled="realizandoRemocao">
                <v-card-title><v-icon left color="success">mdi-sofa-single</v-icon>Sala de Espera - {{ descricaoAgenda }}</v-card-title>
                <v-card-text>
                    <v-data-table
                        v-model="linhaSelecionada"
                        :loading="carregando ? 'success' : null"
                        show-select
                        single-select
                        no-data-text="Nenhum paciente na sala de espera!"
                        loading-text="Buscando pacientes na sala de espera..."
                        :items="pacientesDaSalaDeEspera"
                        :headers="colunas"
                        :footer-props="{
                'itemsPerPageOptions':[3, 5, 10, 15],
                'itemsPerPageText': 'Pacientes por página',
                'pageText': '{0}-{1} de {2}'
            }">
                        
                        <template v-slot:item.espera="{ item }">
                            <b style="color: red;">
                                {{ item.espera }}
                            </b>
                        </template>
                        <template v-slot:item.agendamentoConsulta.horaChegada="{ item }">
                            <a>{{ formataHora(item.agendamentoConsulta.horaChegada) }}</a>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click="fecharModal()">
                        <v-icon left>mdi-keyboard-return</v-icon> Voltar
                    </v-btn>
                    <v-btn :loading="realizandoRemocao" color="error" :disabled="linhaSelecionada.length > 0 ? false : true" @click="abrirModalRemoverPaciente()">
                        <v-icon left>mdi-minus</v-icon> Remover
                    </v-btn>
                    <v-btn v-if="possuiPermissaoCriarAtendimento" :disabled="linhaSelecionada.length > 0 ? false : true" color="primary" @click="atender()">
                        <v-icon left>mdi-stethoscope</v-icon> Atender
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modalRemoverPaciente" max-width="500">
            <v-card>
                <v-card-title>{{ `Remover ${agendamentoARemover.agendamentoConsulta.paciente.nomeCompleto} da sala de espera?` }}</v-card-title>
                <v-card-text>
                    <h4>Situação do agendamento:</h4>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn height="40" block outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                            <v-icon left dense :color="corDaSituacaoAgendamento(
                                                situacaoAgendamento
                                            )
                                                ">
                                                {{
                                                    iconeDaSituacaoAgendamento(
                                                        situacaoAgendamento
                                                    )
                                                }}
                                            </v-icon>
                                            <span :class="`${corDaSituacaoAgendamento(
                                                        situacaoAgendamento
                                                    )}--text`">
                                                {{
                                                    textDaSituacaoAgendamento(
                                                        situacaoAgendamento
                                                    )
                                                }}</span>
                                            <v-icon right>mdi-menu-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="item in opcoesSituacaoAgendamento" :key="item.value" @click="
                                            situacaoAgendamento =
                                            item.value
                                            ">
                                            <v-list-item-title :text-color="item.color">
                                                <v-icon :color="item.color" left dense>{{ item.icon }}</v-icon>
                                                <span :class="`${item.color}--text`">{{ ' ' + item.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="modalRemoverPaciente = false">
                        <v-icon left>mdi-keyboard-return</v-icon> Cancelar
                    </v-btn>
                    <v-btn :loading="realizandoRemocao" color="error" @click="removerAgendamentoDaSalaDeEspera()">
                        <v-icon left>mdi-minus</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ModalAtendimentosNaoFinalizados ref="modalAtendimentosNaoFinalizados"></ModalAtendimentosNaoFinalizados>
    </div>
</template>
  
<script lang="ts">
import Vue from 'vue'
import DateHelper from "../../helpers/DateHelper";
import { AlertaSimplesErro } from "../../helpers/MensagemHelper";
import { AgendamentoService } from "../../services/AgendamentoService";
import Agendamento from '../../models/agendamento/Agendamento';
import { SituacaoAgendamento } from '../../models/enums/EnumSituacaoAgendamento';
import ModalAtendimentosNaoFinalizados from '../../views/atendimento/ModalAtendimentosNaoFinalizados.vue';
import { getToken, getUserPermissions } from '@/utils/Token';
export default Vue.extend({
    components: {
        ModalAtendimentosNaoFinalizados
    },
    name: 'soi-sala-de-espera',
    mounted() {
        this.atualizaListaPacientesNaSalaDeEspera();
        this.contadorTempoDeEspera();
    },
    data() {
        return {
            agendamento: new Agendamento(),
            agendamentoARemover: new Agendamento(),
            carregando: true,
            descricaoAgenda: '',
            realizandoRemocao: false,
            serviceAgendamento: new AgendamentoService(),
            modalAberto: false,
            modalRemoverPaciente: false,
            situacaoAgendamento: 0,
            linhaSelecionada: [],
            pacientesDaSalaDeEspera: new Array(),
            agendaId: 0,
            colunas: [
                {
                    value: "horaAgendamento",
                    text: "Hora",
                },
                {
                    value: "agendamentoConsulta.horaChegada",
                    text: "Chegada"
                },
                {
                    value: "espera",
                    text: "Espera",
                    textvariant: "danger"
                },
                {
                    value: "agendamentoConsulta.paciente.nomeCompleto",
                    text: "Paciente",
                },
                {
                    value: "observacaoAgendamento",
                    text: "Observação",
                }
            ],
            opcoesSituacaoAgendamento: [
                {
                    value: 0,
                    text: 'Marcado',
                    icon: 'mdi-calendar-check',
                    color: '#84b6f4',
                },
                {
                    value: 3,
                    text: 'Faltou',
                    icon: 'mdi-close-octagon-outline',
                    color: '#ff6961',
                },
                {
                    value: 5,
                    text: 'Cancelado',
                    icon: 'mdi-cancel',
                    color: '#ffe180',
                },
            ],
        }
    },
    computed: {
        possuiPermissaoCriarAtendimento(){
            return getUserPermissions(getToken()).includes("AtendimentoCreate");
        }
    },
    methods: {
        selecionarLinha(items: any) {
            this.linhaSelecionada = items;
        },
        fecharModal() {
            this.modalAberto = false;
        },
        async abrirModal(agendaId: number, descricaoAgenda: string) {
            this.descricaoAgenda = descricaoAgenda;
            this.agendaId = agendaId;
            this.linhaSelecionada = [];
            this.pacientesDaSalaDeEspera = new Array();
            this.modalAberto = true;
            await this.preencheLista();
        },
        async preencheLista(){
            this.pacientesDaSalaDeEspera = new Array();
            this.carregando = true;
            await this.serviceAgendamento.GetSalaDeEsperaPorAgenda(this.agendaId).then(
                res => {
                    this.pacientesDaSalaDeEspera = res.data;
                    this.atualizaListaPacientesNaSalaDeEspera();
                },
                err => {
                    AlertaSimplesErro('Houve um problema ao buscar a sala de espera', err);
                    this.modalAberto = false;
                }
            )
            this.carregando = false;

        },
        contadorTempoDeEspera() {
            const timer = setInterval(() => {
                this.atualizaListaPacientesNaSalaDeEspera()
            }, 1000)
        },
        atualizaListaPacientesNaSalaDeEspera() {
            var lista = this.pacientesDaSalaDeEspera;
            this.pacientesDaSalaDeEspera = [];
            lista.forEach(x => {
                x.espera = DateHelper.tempoRelativo(x.agendamentoConsulta.horaChegada)
            })
            this.pacientesDaSalaDeEspera = lista;
        },
        formataHora(data: any) {
            return DateHelper.formataParaHoraMinutoSegundo(data);
        },
        abrirModalRemoverPaciente(){
            if(this.linhaSelecionada.length > 0){
                this.situacaoAgendamento = 0;
                this.agendamentoARemover = this.linhaSelecionada[0];
                this.modalRemoverPaciente = true;
            }
        },
        async removerAgendamentoDaSalaDeEspera(){
            this.realizandoRemocao = true;
            await this.serviceAgendamento.atualizaSituacaoAgendamento(this.agendamentoARemover.id, this.situacaoAgendamento).then(
                res => {},
                err => {
                    AlertaSimplesErro("Não foi possível remover este agendamento da sala de espera!", err);
                }
            );
            this.realizandoRemocao = false;
            this.$emit('atualizaCalendario');
            this.linhaSelecionada = [];
            this.modalRemoverPaciente = false;
            this.preencheLista();
        },
        atender(){
            if(this.linhaSelecionada.length > 0){
                this.agendamento = this.linhaSelecionada[0];
                this.$refs.modalAtendimentosNaoFinalizados.abrirModalAtendimentosNaoFinalizadosSalaDeEspera(this.agendamento);
            }
        },
        //#region <Funções do componente de Situação do Agendamento>
        corDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
            return this.opcoesSituacaoAgendamento.find(
                (x) => x.value == situacaoAgendamento
            )!.color;
        },
        iconeDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
            return this.opcoesSituacaoAgendamento.find(
                (x) => x.value == situacaoAgendamento
            )!.icon;
        },
        textDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
            return this.opcoesSituacaoAgendamento.find(
                (x) => x.value == situacaoAgendamento
            )!.text;
        },
        //#endregion
    }
});
</script>