<template>
    <div class="position-fixed bottom-0" v-if="!exibir">
        <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left" :direction="direction"
            :open-on-hover="hover" :transition="transition">
            <template v-slot:activator>
                <v-btn v-model="fab" color="primary darken-2" class="whatsapp-btn" fab large dark
                    tooltip="Enviar mensagem pelo WhatsApp">
                    <v-icon v-if="fab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-headset
                    </v-icon>
                </v-btn>
            </template>
            <v-tooltip color="green" left :open-on-hover="!aparecerTelMobile()">
                <template v-slot:activator="{ on, attrs }">
                    <a href="https://wa.me/553599958526" target="_blank">
                        <v-btn color="green darken-1" fab medium dark bottom v-bind="attrs" v-on="on">
                            <v-icon size="30" class="mdi mdi-whatsapp mdi-white"></v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Enviar uma mensagem pelo nosso Whatsapp</span>
            </v-tooltip>
            <v-tooltip color="red accent-5" left :open-on-hover="!aparecerTelMobile()">
                <template v-slot:activator="{ on, attrs }">
                    <a href="mailto:comercial@soitic.com?subject=&body=" target="_blank">
                        <v-btn color="red accent-5" fab medium dark bottom v-bind="attrs" v-on="on">
                            <v-icon size="30" class="mdi mdi-email mdi-white"></v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Enviar uma mensagem pelo nosso Email</span>
            </v-tooltip>
            <v-tooltip color="primary accent-5" v-if="mobileTelefone" left :open-on-hover="!aparecerTelMobile()">
                <template v-slot:activator="{ on, attrs }">
                    <a href="tel:08007041133">
                        <v-btn color="primary accent-1" fab medium dark bottom v-bind="attrs" v-on="on">
                            <v-icon size="30" class="mdi mdi-phone mdi-white"></v-icon>
                        </v-btn>
                    </a>
                </template>
            </v-tooltip>
        </v-speed-dial>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("soi-assistencia", {

    props: {
    exibir: {
      type: Boolean,
      required: false
    }
  },
   
    data() {
        return {
            direction: 'top',
            fab: false,
            fling: false,
            hover: false,
            tabs: null,
            top: false,
            right: true,
            bottom: true,
            left: false,
            mobileTelefone: true,
            transition: 'slide-y-reverse-transition',
        };
    },
    mounted() {
        this.mobileTelefone = this.aparecerTelMobile();
    },
    methods: {
        aparecerTelMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

    }
})

</script>

<style scoped>
.bottom-0 {
    position: fixed;
    right: 0;
    bottom: 0;
}

.whatsapp-btn {
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.9);
    }
}
</style>