export default class MaskHelper {
    public static cnpj: string = '##.###.###/####-##';
  
    public static cpf: string = '###.###.###-##';
  
    public static cep: string = '#####-###';
  
    public static data: string = '##/##/####';

    public static telefone: any = ['(##) ####-####', '(##) #####-####']
  
    public static hora: string = '##:##'

    public static altura: string = '#,##';
    
    public static peso: string = '###';
  
  
  }