<template>
    <v-dialog v-model="modalMedicamento" width="800" height="600">
        <v-card>
            <v-toolbar flat outlined>
                <v-toolbar-title>
                    <v-icon color="success">mdi mdi-pill</v-icon>
                    {{ titulo }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fecharModal()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="mt-4">
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-row cols="12">
                        <v-col sm="12" md="6" xs="12">
                            <soi-input :name="'Nome'" :label="'Nome do Medicamento'" v-model="medicamento.nome"
                                :placeholder="'Nome do Medicamento'" :rules="{ required: true }"></soi-input>
                        </v-col>
                        <v-col sm="12" md="6" xs="12">
                            <soi-input :name="'Princípio Ativo'" :label="'Princípio Ativo'"
                                v-model="medicamento.principioAtivo" :placeholder="'Princípio Ativo'"
                                :rules="{ required: true }"></soi-input>
                        </v-col>
                    </v-row>
                    <v-row cols="12">
                        <v-col sm="12" md="12" xs="12">
                            <soi-input :name="'Observação'" :label="'Observação'" v-model="medicamento.observacao"
                                :placeholder="'Observação'"></soi-input>
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="fecharModal()">
                    <v-icon>mdi-cancel</v-icon>Cancelar</v-btn>
                <v-btn color="success" class="mr-2" @click="salvar()" :loading="carregando" :disabled="carregando">
                    <v-icon>mdi mdi-content-save-check</v-icon> Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Medicamento from '@/models/medicamento/Medicamento';
import { MedicamentosService } from '@/services/MedicamentosService';
import { MensagemMixin } from '@/helpers/MensagemHelper';

export default Vue.extend({
    name: 'soi-medicamento',
    data() {
        return {
            modalMedicamento: false,
            carregando: false,
            medicamento: new Medicamento(),
            service: new MedicamentosService(),
            responseOk: false,
            editarMedicamento: false,
        };
    },
    computed: {
        titulo() {
            if (this.editarMedicamento) {
                return 'Editar Medicamento';
            } else {
                return 'Adicionar Medicamento';
            }
        },
    },
    methods: {
        abrirModalMedicamento(medicamento?: Medicamento) {
            this.modalMedicamento = true;
            if (medicamento) {
                this.editarMedicamento = true;
                setTimeout(() => {
                    this.medicamento = Object.assign({}, medicamento);
                }, 10);
            } else {
                this.medicamento = new Medicamento();
            }
        },

        fecharModal() {
            this.medicamento = new Medicamento();
            this.modalMedicamento = false;
        },

        async salvar() {
            this.carregando = true;
            try {
                const valid = await (this.$refs.observer as any).validate();
                if (!valid) {
                    return;
                }

                if (this.editarMedicamento) {
                    this.carregando = true;
                    await this.service.Put(this.medicamento.id, this.medicamento).then(
                        (res) => {
                            MensagemMixin(3000, 'success', 'Medicamento editado com sucesso.');
                            this.responseOk = true;
                            this.editarMedicamento = false;
                            this.modalMedicamento = false;
                        },
                        (err) => {
                            MensagemMixin(3000, 'error', err);
                            this.responseOk = false;
                        }
                    );
                } else {
                    await this.service.Post(this.medicamento).then(
                        (res) => {
                            MensagemMixin(3000, 'success', 'Medicamento cadastrado com sucesso.');
                            this.responseOk = true;
                            this.modalMedicamento = false;
                        },
                        (err) => {
                            MensagemMixin(3000, 'error', err);
                            this.responseOk = false;
                        }
                    );
                }
            } catch (error) {
                MensagemMixin(3000, 'error', error);
            } finally {
                this.carregando = false;
            }
        },
    },
});
</script>
