import Agenda from "./Agenda";

export default class NotaDia {
    public id: number;
    public tenantId?:number
    public agendaId: number;
    public agenda: Agenda;
    public descricao: string;
    public data: string
    



    constructor() {
        this.id = 0;
        this.tenantId = 0
        this.agendaId = 0;
        this.agenda = new Agenda();
        this.descricao = "";
        this.data = '0001-01-01T00:00:00';
        
    }

}