import Usuario from "../usuario/Usuario";
import Agenda from "./Agenda"

export default class AgendaUsuario{
    public agendaId:number
    public agenda: Agenda;
    public usuarioId:number;
    public usuario:Usuario;
    public permiteCadastroHorarios: boolean;
    public permiteCadastroAgendamentos: boolean;
    public permiteCadastroBloqueios: boolean;
    public permiteCadastroFeriados: boolean;
    public permiteCadastroTipoConsulta: boolean;
    public permiteCadastroAvisos: boolean;    
    constructor(){
        this.agendaId = 0;
        this.agenda = new Agenda();
        this.usuarioId = 0;
        this.usuario = new Usuario();
        this.permiteCadastroHorarios = false;
        this.permiteCadastroAgendamentos = false;
        this.permiteCadastroBloqueios = false;
        this.permiteCadastroFeriados = false;
        this.permiteCadastroTipoConsulta = false;
        this.permiteCadastroAvisos = false;
    }
}