<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <soi-input disabled :name="'Nome da Agenda'" :label="'Nome da Agenda '" v-model="descricao"
          :placeholder="'Nome da Agenda'" :rules="{ required: true }">
        </soi-input>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-btn color="success" class="mb-2" @click="gerarToken()" :disabled="tokenGerado" >
          <v-icon>mdi-link-box-variant</v-icon>
          Gerar link
        </v-btn>
      </v-col>
      <v-col v-if="gerarLink" cols="12" md="12" sm="12">
        <soi-input v-model="linkComAgendaId"  disabled :name="'Link'" :label="'Link'" :placeholder="'Link'" />
      </v-col>
      <v-col v-if="gerarLink" cols="12" md="12" sm="12">
        <v-btn class="mb-3 ma-1" color="warning">
          <v-icon>mdi-email-fast</v-icon>
          Enviar por Email
        </v-btn>
        <v-btn color="success" class="mb-2">
          <v-icon>mdi-whatsapp</v-icon>
          Enviar por WhatsApp
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { AgendaService } from '@/services/AgendaService';
import Agenda from '@/models/agenda/Agenda';
import { AgendamentoOnlineService } from '@/services/AgendamentoOnlineService';
import Tenant from '@/models/tenant/Tenant';
import { TenantService } from '@/services/TenantService';
import { AlertaSimplesErro } from '@/helpers/MensagemHelper';
export default Vue.extend({
  name: "soi-agendamento-online",
  props: {
    descricao: {
      type: String,
      required: true
    },
    idAgenda: {
      type: Number,
      required: true
    }
  },
  computed: {
    tituloAviso() {
      return "Link para o Agendamento Online";
    },
    iconeTituloAviso() {
      return "mdi-plus";
    },
  },
  watch: {
  },
  data() {
    return {
      carregando: false,
      agenda: new Agenda(),
      tenant: new Tenant(),
      descricaoAgenda: '',
      id: '',
      serviceAgenda: new AgendaService(),
      onlineService: new AgendamentoOnlineService(),
      serviceTenant: new TenantService(),
      agendaSelecionada: '',
      modalAgendamentoOnline: false,
      linkComAgendaId: '',
      gerarLink: false,
      tokenGerado: false
    };
  },
  methods: {

    async gerarToken() {
    this.gerarLink = true
    if (!this.tokenGerado) {
      try {
        await this.onlineService.GerarToken(this.idAgenda).then(
          (res) => {
            this.linkComAgendaId = res.data;
            this.tokenGerado = true;
          }
        );
      } catch (error) {
        console.error('Erro ao gerar o token:', error);
      }
    }
  },
  },
})
</script>