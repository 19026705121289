import Role from "@/constants/enums/Role";
import Permissao from '@/constants/enums/PermissoesPadrao';


export interface IMenuItem {
    name: string;
    params?: any;
    path: string;
    label: string;
    icon: string;
    children?: Array<IMenuItem>;
    relatedRoutes?: Array<string>;
    roles: Array<string>;
    permissions: Array<string>;
}

const items: Array<IMenuItem> = [
    {
        name: 'home',
        path: '/home',
        label: 'Home',
        icon: 'mdi-home-outline',
        roles: [
            Role.Admin,
            Role.Host,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador

        ],
        permissions: []
    },
    {
        name: 'clinicas',
        path: '/clinicas',
        label: 'Clínicas',
        icon: 'mdi-hospital-box-outline',
        relatedRoutes: [
            'clinicasForm',
            'clinicasFormUpdate',
            'clinicasFormView',
        ],
        roles: [
            Role.Host,
        ],
        permissions: [Permissao.TenantRead]
    },
    {
        name: 'Agenda',
        path: '/agenda',
        label: 'Agendas',
        icon: 'mdi-calendar-multiselect-outline',
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador
        ],
        permissions: [Permissao.AgendaRead]
    },
    {
        name: 'usuarios',
        path: '/usuarios',
        label: 'Usuarios',
        icon: 'mdi-shield-account-outline',
        relatedRoutes: [
            'usuario',
            'visualizarUsuario'
        ],
        roles: [
            Role.Admin,
            Role.Medico,
        ],
        permissions: [Permissao.UsuarioRead]
    },
    {
        name: 'convenios',
        path: '/convenios',
        label: 'Convênios',
        icon: 'mdi-bottle-tonic-plus-outline',
        relatedRoutes: [
            'conveniosForm',
            'conveniosFormUpdate',
            'conveniosFormView',
        ],
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador

        ],
        permissions: [Permissao.ConvenioRead]
    },
    {
        name: 'pacientes',
        path: '/pacientes',
        label: 'Pacientes',
        icon: 'mdi-account-group-outline',
        relatedRoutes: [
            'pacientesForm',
            'pacientesFormUpdate',
            'pacientesFormView',
        ],
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.TecEnfermagem,
            Role.Enfermeiro,
            Role.Administrador

        ],
        permissions: [Permissao.PacienteRead]
    },
    {
        name: 'atendimentos',
        path: '/atendimentos',
        label: 'Atendimento',
        icon: 'mdi-stethoscope',
        relatedRoutes: [
            'atendimentoForm',
            'atendimentoFormUpdate',
        ],
        roles: [
            Role.Medico,
            Role.Secretario,
            Role.Enfermeiro,

        ],
        permissions: []
    },
    {
        name: 'consultasPersonalizadas',
        path: '/consultas-personalizadas',
        label: 'Consultas Personalizadas',
        icon: 'mdi-clipboard-pulse',
        relatedRoutes: [
            'consultaPersonalizadaForm'
        ],
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.Enfermeiro,
            Role.Administrador

        ],
        permissions: []
    },
    {
        name: 'quadrosCustomizados',
        path: '/quadros-customizados',
        label: 'Quadros Customizados',
        icon: 'mdi-application-cog',
        relatedRoutes: [
            'quadrosCustomizadosForm'
        ],
        roles: [
            Role.Admin,
            Role.Medico,
            Role.Secretario,
            Role.Enfermeiro,
            Role.Administrador

        ],
        permissions: []
    },

    {
        name: 'triagens',
        path: '/triagens',
        label: 'Triagens Médicas',
        icon: 'mdi-heart-pulse', 
        relatedRoutes: [
            'triagemForm',
            'triagemFormView'
        ],
        roles: [
            Role.Admin,
            Role.Medico,            
            Role.Administrador
        ],
        permissions: [
            Permissao.AcessoTriagem            
        ]
    },
    
    {
        name: 'logsAuditoria',
        path: '/logs-auditoria',
        label: 'Auditoria',
        icon: 'mdi-file-cog',        
        roles: [
            Role.Host,
            Role.Admin,
            Role.Medico,
        ],
        relatedRoutes: [
            'logsAuditoria'
        ],
        permissions: [Permissao.AuditoriaRead]
    },

    {
        name: 'impressosPersonalizados',
        path: '/impressos-personalizados',
        label: 'Impressos Personalizados',
        icon: 'mdi mdi-file-edit',
        roles: [
            Role.Host,
            Role.Admin,
            Role.Medico,
        ],
        relatedRoutes: [
            'impressosPersonalizados'
        ],
        permissions: [Permissao.ImpressoPersonalizadoRead]
    },
]

export default items;