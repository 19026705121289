import { SituacaoAgendamento } from "../enums/EnumSituacaoAgendamento";
import { TipoAviso } from "../enums/EnumTipoAviso";
import { TipoConsultaRetorno } from "../enums/EnumTipoConsultaRetorno";
import Agenda from "./Agenda";
import TipoConsulta from "./TipoConsulta";

export default class ConfiguracaoAviso{
    public id:number;
    public tenantId?:number;
    public agendaId:number;
    public agenda:Agenda;
    public assunto:string;
    public mensagem:string;
    public consultaRetorno?: TipoConsultaRetorno;
    public situacaoAgendamento?:SituacaoAgendamento;
    public tipoAviso?: TipoAviso
    public tempoAntesAviso:string;
    public email:boolean;
    public sms:boolean;
    
    constructor(){
        this.id = 0;
        this.agendaId = 0;
        this.agenda = new Agenda();
        this.assunto = "";
        this.mensagem = "";
        this.tempoAntesAviso = "";
        this.email = true;
        this.sms = false;
    }
}