import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#2196f3',
                secondary: '#acafbb',
                accent: '#ffc107',
                error: '#f44336',
                warning: '#ffb244',
                info: '#dde1e7',
                success: '#2dcb73',      
                background: '#e8ecf3',
                white: '#ffffff',
            },
            dark: {
                primary: '#2196f3',
                secondary: '#acafbb',
                accent: '#ffc107',
                error: '#f44336',
                warning: '#ffb244',
                info: '#dde1e7',
                success: '#2dcb73',      
            },
        },        
    }
});
