<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-data-table
          :loading="carregando ? 'success' : null"
          no-data-text="Nenhum Bloqueio encontrada(o)!"
          loading-text="Carregando os itens desta tabela! Aguarde..."
          :headers="colunasBloqueios"
          :header-props="headerProps"
          :items="listaBloqueios"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="botao-novo" cols="12" sm="12" md="12">
                <v-btn
                  depressed
                  color="success"
                  class="mb-2"
                  @click="abrirModalBloqueios()"
                >
                  <v-icon>mdi-plus</v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              x-small
              fab
              color="success"
              @click="abrirModalBloqueiosParaEdicao(item)"
              title="Editar Bloqueio"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              x-small
              fab
              color="error"
              @click="excluirBloqueio(item)"
              title="Excluir Bloqueio"
            >
              <v-icon small>mdi-minus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
              transition="dialog-bottom-transition"
              v-model="modalBloqueios"
              max-width="700px"
            >
            <v-card>
              <v-card-title>
                <v-icon left color="success">{{ iconeTituloBloqueios }}</v-icon>{{ tituloBloqueios }}
              </v-card-title>
              <validation-observer
                ref="observerBloqueios"
                v-slot="{}"
              >
                <v-form>
                  <v-card class="elevation-0">
                    <v-card-text class="pa-5">
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <soi-input
                            type="date"
                            :name="'Início'"
                            v-model="bloqueioAgenda.dataInicio"
                            :label="'Início'"
                            :rules="{ required: true }"
                          ></soi-input>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <soi-input
                            type="date"
                            :name="'Fim'"
                            v-model="bloqueioAgenda.dataFim"
                            :label="'Fim'"
                            :rules="{ required: true }"
                          ></soi-input>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <soi-input
                            :name="'Descrição'"
                            v-model="bloqueioAgenda.descricao"
                            :label="'Descrição'"
                            :rules="{ required: true }"
                          ></soi-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="warning" @click="fecharModal()">
                        <v-icon left>mdi-keyboard-return</v-icon>
                        Voltar
                      </v-btn>
                      <v-btn
                        @click="salvar()"
                        color="success"
                        :loading="carregando" :disabled="carregando"
                      >
                        <v-icon left>mdi-checkbox-marked-circle</v-icon>
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </validation-observer>
            </v-card>
          </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import DateHelper from "../../helpers/DateHelper";
import { AlertaExclusaoQuestaoBooleano } from "../..//helpers/MensagemHelper";
import BloqueioHorarioAgenda from "../..//models/agenda/BloqueioHorarioAgenda";
import { MensagemMixin } from "../../helpers/MensagemHelper";
export default Vue.extend({
  name: "soi-bloqueioAgenda",
  props: {
    value: {
      type: Array as () => BloqueioHorarioAgenda[],
      required: true,
    },
  },
  watch: {
    value() {
      this.listaBloqueios = this.value;
    },
  },
  computed: {
    tituloBloqueios() {
      return this.editarBloqueios ? "Editar Bloqueios" : "Criar Bloqueios";
    },
    iconeTituloBloqueios() {
      return this.editarBloqueios ? "mdi-pencil" : "mdi-plus";
    }
  },
  data() {
    return {
      carregando: false,
      bloqueioAgenda: new BloqueioHorarioAgenda(),
      listaBloqueios: this.value,
      modalBloqueios: false,
      editarBloqueios: false,
      bloqueioAgendaEdicaoIndex: -1,
      headerProps: {
        sortByText: "Ordenar por",
      },
      colunasBloqueios: [
        {
          text: "Início",
          align: "center",
          value: "dataInicio",
          filterable: true,
          sortable: true,
        },
        {
          text: "Fim",
          align: "center",
          value: "dataFim",
          filterable: true,
          sortable: true,
        },
        {
          text: "Descrição",
          align: "center",
          value: "descricao",
          filterable: true,
        },
        {
          text: "Ações",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async salvar(): Promise<void> {
      const { dataInicio, dataFim } = this.bloqueioAgenda;
      const valid = await (
        this.$refs.observerBloqueios as { validate: () => Promise<boolean> }
      ).validate();
      if (!valid) return;

      if (!this.validaDatas(dataInicio, dataFim)) {
        await (this.$refs.observerBloqueios as any).reset();
        return;
      }

      this.bloqueioAgenda.dataInicio =
        DateHelper.formataParaDiaMesAno(dataInicio);
      this.bloqueioAgenda.dataFim = DateHelper.formataParaDiaMesAno(dataFim);

      if (this.editarBloqueios) {
        if (this.bloqueioAgendaEdicaoIndex !== -1) {
          Object.assign(
            this.listaBloqueios[this.bloqueioAgendaEdicaoIndex],
            this.bloqueioAgenda
          );
        }
      } else {
        this.listaBloqueios.push(this.bloqueioAgenda);
      }

      this.$emit("input", this.listaBloqueios);
      this.modalBloqueios = false;
      this.editarBloqueios = false;
      await (this.$refs.observerBloqueios as any).reset();
      this.bloqueioAgenda = new BloqueioHorarioAgenda();
    },
    validaDatas(dataInicio: string, dataFim: string) {
      var retorno: boolean = true;
      var inicio: Date = new Date(dataInicio);
      var fim: Date = new Date(dataFim);
      if (inicio > fim) {
        retorno = false;
        MensagemMixin(
          3000,
          "error",
          "A data final deve ser após a data inicial!"
        );
        return retorno;
      }
      return retorno;
    },
    abrirModalBloqueios() {
      this.modalBloqueios = true;
      this.bloqueioAgenda = new BloqueioHorarioAgenda();
    },
    async abrirModalBloqueiosParaEdicao(bloqueio: BloqueioHorarioAgenda) {
      this.bloqueioAgendaEdicaoIndex = this.listaBloqueios.indexOf(bloqueio);
      this.editarBloqueios = true;
      this.modalBloqueios = true;
      setTimeout(() => {
        this.bloqueioAgenda = Object.assign({}, bloqueio);
        this.bloqueioAgenda.dataInicio =
          DateHelper.formatarParaAnosMesDiaSubstring(bloqueio.dataInicio);
        this.bloqueioAgenda.dataFim =
          DateHelper.formatarParaAnosMesDiaSubstring(bloqueio.dataFim);
      }, 10);
    },
    async excluirBloqueio(bloqueio: BloqueioHorarioAgenda) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.listaBloqueios.indexOf(bloqueio);
        this.listaBloqueios.splice(index, 1);
        this.$emit("input", this.listaBloqueios);
      }
    },
    async fecharModal() {
      this.modalBloqueios = false;
      this.editarBloqueios = false;
      await (this.$refs.observerBloqueios as any).reset();
      this.bloqueioAgenda = new BloqueioHorarioAgenda();
    },
  },
});
</script>
