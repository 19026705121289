<template>
    <v-dialog v-model="modalAberto" transition="dialog-bottom-transition" max-width="900" persistent>
        <v-card :loading="carregando ? 'success' : null" :disabled="carregando">
            <v-toolbar flat outlined>
                <v-toolbar-title><v-icon color="success" left size="x-large">mdi-bookmark-plus-outline</v-icon>{{ titulo
                }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="fecharModal()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <validation-observer ref="observer" v-slot="{ }">
                <form @submit.prevent="salvar">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <v-btn-toggle dense v-model="agendamento.tipoAgendamento" group class="mb-n6 mt-n6"
                                    @change="mudarTipoAgendamento">
                                    <v-btn class="buttons-toggle" small :value="tipoAgendamento.Consulta"
                                        :disabled="editarAgendamento || agendamento.tipoAgendamento === tipoAgendamento.Consulta">
                                        Consulta
                                    </v-btn>

                                    <v-btn v-if="!agendamentoRetorno && !preConsultaPaciente" class="buttons-toggle" small
                                        :value="tipoAgendamento.Compromisso"
                                        :disabled="editarAgendamento || agendamento.tipoAgendamento === tipoAgendamento.Compromisso">
                                        Compromisso
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>

                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta &&
                                !agendamentoRetorno
                                " cols="12" md="3" sm="12">
                                <soi-select v-model="agendamento.agendamentoConsulta
                                    .tipoConsultaId
                                    " label="Tipo de Consulta" :items="options_consulta">
                                </soi-select>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ===
                                tipoAgendamento.Consulta &&
                                preConsultaPaciente
                                " cols="12" md="4" sm="12">
                                <soi-input v-model="agendamento.agendamentoConsulta.paciente
                                    .nome
                                    " :label="'Nome *'" :name="'Nome'" prepend-icon="mdi-account-plus"
                                    :rules="{ required: true }"></soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ===
                                tipoAgendamento.Consulta &&
                                preConsultaPaciente
                                " cols="12" md="4" sm="12">
                                <soi-input v-model="agendamento.agendamentoConsulta.paciente
                                    .sobrenome
                                    " :label="'Sobrenome *'" :name="'Sobrenome'"
                                    :rules="{ required: true }"></soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ===
                                tipoAgendamento.Consulta &&
                                !preConsultaPaciente
                                " cols="12" :md="agendamentoRetorno || editarAgendamento ? 9 : 8" sm="12">
                                <v-autocomplete :disabled="agendamentoRetorno" v-model="agendamento.agendamentoConsulta
                                    .pacienteId
                                    " :items="listaPacientesFiltro" :loading="carregandoBuscarPaciente"
                                    :search-input.sync="buscarPeloPaciente" color="success" outlined dense item-text="nome"
                                    item-value="value" :filter="filtrarPaciente"
                                    no-data-text="Não há registros a serem exibidos." prepend-icon="mdi-account-search"
                                    :hint="agendamentoRetorno
                                        ? ''
                                        : 'Caso o paciente não possua cadastro, clique no botão ao lado'
                                        " persistent-hint :hide-no-data="!buscarPeloPaciente" label="Paciente *"
                                    placeholder="Comece a digitar para buscar" @change="atualizarDadosPaciente">
                                    <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{
                                                    item.nome
                                                }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{
                                                    item.cpf
                                                }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                    tipoAgendamento.Compromisso
                                    " cols="12" md="12" sm="12">
                                <soi-input v-model="agendamento.agendamentoCompromisso
                                    .descricao
                                    " :label="'Descrição do compromisso *'" :name="'Descrição'"
                                    :rules="{ required: true }">
                                </soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta &&
                                !agendamentoRetorno &&
                                !editarAgendamento
                                " cols="12" md="1" sm="12">
                                <v-tooltip bottom color="success">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn block color="success" @click="alteraVariavelNovoPaciente()" v-bind="attrs"
                                            v-on="on">
                                            <v-icon>
                                                {{
                                                    preConsultaPaciente
                                                    ? 'mdi-account-search'
                                                    : 'mdi-account-plus'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </template>

                                    <span>{{
                                        preConsultaPaciente
                                        ? 'Buscar Paciente'
                                        : 'Criar novo paciente'
                                    }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field name="data" color="success" outlined dense v-model="agendamento.dataAgendamento
                                            " label="Data *" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
                                            readonly v-mask="masks.data" @blur="
                                                date = parseDate(
                                                    agendamento.dataAgendamento
                                                )
                                                " v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="success" v-model="date" @change="
                                        buscarHorarios(
                                            agendamento.agendaId,
                                            date
                                        )
                                        " no-title @input="menu1 = false" locale="pt-BR"
                                        :allowed-dates="desabilitarDatas"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <validation-provider v-slot="{ errors }" name="Horário" :rules="{ required: true }">
                                    <v-select name="Horário" @input="preencherDuracaoAgendamento()"
                                        v-model="agendamento.horaAgendamento" :error-messages="errors" label="Horário *"
                                        no-data-text="Configure os horários" :items="horarios" :nullable="false" outlined
                                        dense color="success" item-color="success">
                                    </v-select>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <validation-provider v-slot="{ errors }" name="Duração" :rules="{ required: true }">
                                    <v-text-field label="Duração *" name="Duração" v-model="agendamento.duracaoAgendamento"
                                        type="time" dense outlined color="success" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col v-if="!agendamentoRetorno" cols="12" md="3" sm="12">
                                <v-menu bottom right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn height="40" block outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                            <v-icon left dense :color="corDaSituacaoAgendamento(
                                                agendamento.situacaoAgendamento
                                            )
                                                ">
                                                {{
                                                    iconeDaSituacaoAgendamento(
                                                        agendamento.situacaoAgendamento
                                                    )
                                                }}
                                            </v-icon>
                                            <span :class="`${corDaSituacaoAgendamento(
                                                        agendamento.situacaoAgendamento
                                                    )}--text`">
                                                {{
                                                    textDaSituacaoAgendamento(
                                                        agendamento.situacaoAgendamento
                                                    )
                                                }}</span>
                                            <v-icon right>mdi-menu-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="item in opcoesSituacaoAgendamento" :key="item.value" @click="
                                            agendamento.situacaoAgendamento =
                                            item.value
                                            ">
                                            <v-list-item-title :text-color="item.color">
                                                <v-icon :color="item.color" left dense>{{ item.icon }}</v-icon>
                                                <span :class="`${item.color}--text`">{{ ' ' + item.text }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col v-if="preConsultaPaciente" cols="12" md="3" sm="12">
                                <soi-input :name="'Data de Nascimento'" :label="'Data de Nascimento *'" :disabled="disabled"
                                    :rules="{ required: true }"
                                    v-model="agendamento.agendamentoConsulta.paciente.dataNascimento" type="date"
                                    @input="validarDataNascimentoParaDesabilitarTriagem">
                                </soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta &&
                                !agendamentoRetorno
                                " cols="12" md="4" sm="12">
                                <soi-input :label="'CPF'" :name="'CPF'" v-model="cpfPaciente"
                                    :disabled="!preConsultaPaciente" :placeholder="'000.000.000-00'" :mask="masks.cpf"
                                    :rules="{
                                        min: 14,
                                        cpf: 'cpf',
                                        required: true,
                                    }">
                                </soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta &&
                                !agendamentoRetorno
                                " cols="12" md="5" sm="12">
                                <soi-input :label="'Email'" :name="'Email'" v-model="agendamento.agendamentoConsulta.email
                                    "></soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta
                                " cols="12" md="3" sm="12">
                                <soi-input :label="'Telefone'" :name="'Telefone'" v-model="agendamento.agendamentoConsulta.telefone
                                    " :mask="masks.telefone"></soi-input>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta
                                " cols="12"
                                :md="agendamento.tipoAgendamento == tipoAgendamento.Consulta && !preConsultaPaciente ? 6 : 4"
                                sm="12">
                                <soi-select v-model="agendamento.agendamentoConsulta
                                    .convenioId
                                    " label="Convênio" :items="options_convenio" @input="mudarPlanos">
                                </soi-select>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta
                                " cols="12"
                                :md="agendamento.tipoAgendamento == tipoAgendamento.Consulta && !preConsultaPaciente ? 6 : 5"
                                sm="12">
                                <soi-select v-model="agendamento.agendamentoConsulta.planoId
                                    " label="Plano" :items="options_plano">
                                </soi-select>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center mb-6">
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta
                                " cols="12" md="2" sm="12">
                                <soi-switch class="mt-n1" v-model="agendamento.agendamentoConsulta.retorno
                                    " :label="'Retorno'" :disabled="agendamentoRetorno"></soi-switch>
                            </v-col>
                            <v-col v-if="agendamento.tipoAgendamento ==
                                tipoAgendamento.Consulta
                                " cols="12" md="4" sm="12">
                                <soi-switch class="mt-n1" v-model="agendamento.agendamentoConfirmado"
                                    :label="'Agendamento confirmado'"></soi-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-textarea label="Observação" outlined rows="1" color="success"
                                v-model="agendamento.observacaoAgendamento">
                            </v-textarea>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn id="triagem" color="primary" :loading="carregando"
                            :disabled="desabilitarBotaoTriagem || agendamento.tipoAgendamento === tipoAgendamento.Compromisso || desabilitaModulosComplementares()"
                            v-show="!desabilitaModulosComplementares()" @click="realizarTriagemParaForm()">
                            <v-icon left>mdi-checkbox-marked-circle</v-icon>
                            Realizar Triagem
                        </v-btn>
                        <v-btn dense color="warning" @click="fecharModal()" :loading="carregando">
                            <v-icon left>mdi-keyboard-return</v-icon> Voltar
                        </v-btn>
                        <v-btn id="salvar" type="input" color="success" :loading="carregando" :disabled="carregando">
                            <v-icon left>mdi-checkbox-marked-circle</v-icon>
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </form>
            </validation-observer>
        </v-card>

        <v-dialog v-model="modalTriagens" :max-width="isMobile ? '100%' : '1600'">
            <v-card class="text-center">
                <v-toolbar flat outlined>
                    <v-toolbar-title><v-icon color="success" left size="x-large">mdi-heart-pulse</v-icon>Realizar
                        Triagem</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="fecharModalTriagem()"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>

                <soi-dados-padrao-triagem ref="modalTriagem" :modalTriagem="modalTriagens"
                    :rotaModalAgendamento="'ModalAgendamento'" @triagemSalva="fecharModalTriagem"
                    @atualizaCalendario="$emit('atualizaCalendario');"></soi-dados-padrao-triagem>
            </v-card>

        </v-dialog>

    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {
    AlertaSimples,
    AlertaSimplesErro,
    MensagemMixin,
} from '../../helpers/MensagemHelper';
import Agendamento from '../../models/agendamento/Agendamento';
import { AgendamentoService } from '../../services/AgendamentoService';
import { TipoConsultaService } from '../../services/TipoConsultaService';
import { ConvenioService } from '../../services/ConveioService';
import { PlanoService } from '../../services/PlanoService';
import { PacientesService } from '../../services/PacientesService';
import MaskHelper from '../../helpers/MaskHelper';
import DateHelper from '../../helpers/DateHelper';
import { TipoAgendamento } from '../../models/enums/EnumTipoAgendamento';
import { SituacaoAgendamento } from '../../models/enums/EnumSituacaoAgendamento';
import AgendamentoConsulta from '../../models/agendamento/AgendamentoConsulta';
import AgendamentoCompromisso from '../../models/agendamento/AgendamentoCompromisso';
import { BloqueioHorarioAgendaService } from '../../services/BloqueioHorarioAgendaService';
import { FeriadoAgendaService } from '../../services/FeriadoAgendaService';
import BloqueioHorarioAgenda from '../../models/agenda/BloqueioHorarioAgenda';
import FeriadoAgenda from '../../models/agenda/FeriadoAgenda';
import FormTriagem from '../../views/triagem/FormTriagem.vue';
import Agenda from '../../models/agenda/Agenda';
import Triagem from "../../models/triagem/Triagem";
import Pacientes from "../../models/pacientes/Pacientes";
import AuthUserModel from '@/models/Auth/AuthUserModel';
import { decode, getToken } from '@/utils/Token';
import PermissoesPadrao from '@/constants/enums/PermissoesPadrao';
import { TriagemService } from '@/services/TriagemService';



export default Vue.component('soi-modalAgendamento', {
    name: 'soi-modalAgendamento',
    components: {
        FormTriagem
    },
    data() {
        return {
            //data
            date: DateHelper.formataParaAnoMesDia(
                new Date(Date.now()).toISOString().substr(0, 10)
            ),
            dateFormatted: DateHelper.formataParaDiaMesAno(
                new Date(Date.now()).toISOString().substr(0, 10)
            ),
            menu1: false,
            menu2: false,
            modalAberto: false,
            modalTriagens: false,

            // triagem aqui
            triagem: new Triagem(),
            paciente: new Pacientes(),
            dataNascimentoValido: false,
            desabilitarBotaoTriagem: false,

            //buscarPacienteAutoComplete
            listaPacientesFiltro: new Array<{}>(),
            carregandoBuscarPaciente: false,
            buscarPeloPaciente: '',

            idAgenda: 1,
            agenda: new Agenda(),
            agendamento: new Agendamento(),
            editarAgendamento: false,

            //Pre-Consulta
            corFundo: 'success',
            corBotao: 'success',
            preConsultaPaciente: false,

            //AgendamentoRetorno
            agendamentoRetorno: false,
            editarAgendamentoRetorno: '',

            //services
            agendamentoService: new AgendamentoService(),
            tipoConsultaService: new TipoConsultaService(),
            convenioService: new ConvenioService(),
            planoService: new PlanoService(),
            pacienteService: new PacientesService(),
            bloqueiosHorariosService: new BloqueioHorarioAgendaService(),
            feriadosService: new FeriadoAgendaService(),
            triagemService: new TriagemService(),

            tipoAgendamento: TipoAgendamento,
            carregando: false,
            masks: MaskHelper,
            opcoesSituacaoAgendamento: [
                {
                    value: 0,
                    text: 'Marcado',
                    icon: 'mdi-calendar-check',
                    color: '#84b6f4',
                },
                {
                    value: 1,
                    text: 'Em espera',
                    icon: 'mdi-clock-alert-outline',
                    color: '#77dd77',
                },
                {
                    value: 2,
                    text: 'Em andamento',
                    icon: 'mdi-progress-check',
                    color: '#ffda9e',
                },
                {
                    value: 3,
                    text: 'Faltou',
                    icon: 'mdi-close-octagon-outline',
                    color: '#ff6961',
                },
                {
                    value: 4,
                    text: 'Finalizado',
                    icon: 'mdi-check-circle-outline',
                    color: '#c5c6c8',
                },
                {
                    value: 5,
                    text: 'Cancelado',
                    icon: 'mdi-cancel',
                    color: '#ffe180',
                },
            ],
            options_convenio: [],
            options_plano: [],
            options_consulta: [],
            horarios: Array<{ text: string; value: string }>(),
            horariosAgenda: [],
            datasBloqueadas: Array<BloqueioHorarioAgenda>(),
            feriados: Array<FeriadoAgenda>(),
            minDate: new Date(Date.now()),
            tempListaHorarios: [],
            idPaciente: 0,
            idAgendamento: 0,

            //cpf
            cpfPaciente: '',
        };
    },

    watch: {
        date() {
            this.agendamento.dataAgendamento = DateHelper.formataParaDiaMesAno(
                this.date
            );
        },

        buscarPeloPaciente(val: string) {
            this.carregandoBuscarPaciente = true;

            if (!val || val.length < 2) {
                this.listaPacientesFiltro = [];
                this.carregandoBuscarPaciente = false;
                return;
            }

            this.pacienteService.BuscarPaciente(val)
                .then((res) => {

                    this.listaPacientesFiltro = res.data.itens
                        .filter((paciente: any) => paciente.ativo)
                        .map((paciente: any) => ({
                            value: paciente.id,
                            nome: paciente.nomeCompleto,
                            cpf: paciente.cpf,
                        }));
                })
                .catch((err) => {
                   
                })
                .finally(() => {
                    this.carregandoBuscarPaciente = false;
                });
        },

        'agendamento.tipoAgendamento'() {
            if (this.agendamento.tipoAgendamento == TipoAgendamento.Consulta) {
                this.opcoesSituacaoAgendamento = [
                    {
                        value: 0,
                        text: 'Marcado',
                        icon: 'mdi-calendar-check',
                        color: '#84b6f4',
                    },
                    {
                        value: 1,
                        text: 'Em espera',
                        icon: 'mdi-clock-alert-outline',
                        color: '#77dd77',
                    },
                    {
                        value: 2,
                        text: 'Em andamento',
                        icon: 'mdi-progress-check',
                        color: '#FFA500',
                    },
                    {
                        value: 3,
                        text: 'Faltou',
                        icon: 'mdi-close-octagon-outline',
                        color: '#ff6961',
                    },
                    {
                        value: 4,
                        text: 'Finalizado',
                        icon: 'mdi-check-circle-outline',
                        color: '#c5c6c8',
                    },
                    {
                        value: 5,
                        text: 'Cancelado',
                        icon: 'mdi-cancel',
                        color: '#FFD700',
                    },
                ];
            } else {
                this.opcoesSituacaoAgendamento = [
                    {
                        value: 0,
                        text: 'Marcado',
                        icon: 'mdi-calendar-check',
                        color: '#84b6f4',
                    },
                    {
                        value: 3,
                        text: 'Faltou',
                        icon: 'mdi-close-octagon-outline',
                        color: '#ff6961',
                    },
                    {
                        value: 4,
                        text: 'Finalizado',
                        icon: 'mdi-check-circle-outline',
                        color: '#c5c6c8',
                    },
                    {
                        value: 5,
                        text: 'Cancelado',
                        icon: 'mdi-cancel',
                        color: '#ffe180',
                    },
                ];
                if (
                    !this.opcoesSituacaoAgendamento.find(
                        (x) => x.value == this.agendamento.situacaoAgendamento
                    )
                )
                    this.agendamento.situacaoAgendamento =
                        SituacaoAgendamento.Marcado;
            }
        },
    },


    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

    },

    computed: {


        cpfValido() {
            return this.$rules.cpf.validate(this.cpfPaciente);
        },

        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        titulo() {
            if (this.editarAgendamento) return 'Editar Agendamento';
            else if (
                this.preConsultaPaciente &&
                this.agendamento.tipoAgendamento == TipoAgendamento.Consulta
            )
                return 'Pré Consulta';
            else if (this.agendamentoRetorno)
                return 'Criar Agendamento Retorno';
            else return 'Criar Agendamento';
        },
    },


    methods: {
        //#region <Funções de abertura e fechamento do Modal de Agendamento>
        async abrirModal(
            agenda: Agenda,
            data?: string,
            idPacienteRetorno?: number,
            horario?: string
        ) {
            try {
                this.agenda = agenda;
                this.idAgenda = agenda.id;
                const dataEstaBloqueada = data && (await this.dataEstaBloqueada(data));
                if (dataEstaBloqueada) return;

                if (!data) {
                    await this.buscarBloqueiosEFeriados();
                    data = await this.buscarProximaDataDisponivel();
                    if (data === undefined) return;
                }

                this.agendamento = new Agendamento();
                if (
                    !(await this.buscarHorarios(
                        this.idAgenda,
                        DateHelper.formatarParaAnosMesDiaISO(data)
                    ))
                ) {
                    MensagemMixin(
                        8000,
                        'error',
                        `Este dia não possui mais horários disponíveis!`
                    );
                    this.fecharModal();
                    return;
                }

                if (idPacienteRetorno) {
                    this.agendamentoRetorno = true;
                    this.agendamento.agendamentoConsulta.retorno = true;
                    this.agendamento.agendamentoConsulta.pacienteId =
                        idPacienteRetorno;
                    this.pacienteService.Get(idPacienteRetorno).then((res) => {
                        this.buscarPeloPaciente =
                            res.data.nome + ' ' + res.data.sobrenome;
                        this.cpfPaciente = res.data.cpf;
                    });
                }

                this.modalAberto = true;
                this.carregando = true;
                this.desabilitarBotaoTriagem = false;

                await Promise.all([
                    this.preencherTipoConsulta(this.idAgenda),
                    this.preencherConvenios(),
                ]);

                this.agendamento.horaAgendamento = horario ? this.horarios.find(x => x.value >= horario).value : this.horarios[0].value;
                this.agendamento.situacaoAgendamento =
                    SituacaoAgendamento.Marcado;
                this.agendamento.agendaId = this.idAgenda;
                this.agendamento.dataAgendamento =
                    DateHelper.formataParaDiaMesAno(data);
                this.date = DateHelper.formataParaAnoMesDia(data);

                this.preencherDuracaoAgendamento();

                this.carregando = false;
            } catch (error) {
                AlertaSimplesErro('Aviso:', error);
                this.fecharModal();
            }
        },

        async abrirModalParaEdicao(
            idAgendamento: number,
            agenda: Agenda,
            agendamentoRetorno?: Agendamento
        ) {
            try {
                this.agenda = agenda;
                this.idAgenda = agenda.id;
                await Promise.all([
                    this.preencherTipoConsulta(this.idAgenda),
                    this.preencherConvenios(),
                ]);
                this.editarAgendamento = true;
                this.agendamento = new Agendamento();
                this.carregando = true;
                this.modalAberto = true;

                var data;
                var agendamento;
                var agendamentoConsulta;
                var dataAgendamento;
                if (!agendamentoRetorno) {
                    await this.agendamentoService
                        .Get(idAgendamento)
                        .then((res) => {
                            data = res.data;
                        });
                    agendamento = data;
                    agendamentoConsulta = agendamento.agendamentoConsulta;
                    dataAgendamento = agendamento.dataAgendamento;
                } else {
                    data = agendamentoRetorno;
                    agendamento = agendamentoRetorno;
                    agendamentoConsulta =
                        agendamentoRetorno.agendamentoConsulta;
                    dataAgendamento = agendamentoRetorno.dataAgendamento;
                }


                if (agendamentoConsulta) {
                    await this.triagemService.GetTriagemPorAgendamentoId(idAgendamento).then((res) => {
                        this.desabilitarBotaoTriagem = true;
                    }).catch((error) => {
                        this.desabilitarBotaoTriagem = false;
                    });
                } else {
                    this.desabilitarBotaoTriagem = true;

                }



                await this.buscarHorarios(
                    this.idAgenda,
                    DateHelper.formatarParaAnosMesDiaISO(dataAgendamento)
                );
                this.horarios.push({
                    text: agendamento.horaAgendamento,
                    value: agendamento.horaAgendamento,
                });

                agendamentoConsulta?.convenioId &&
                    (await this.preencherPlanos(
                        agendamentoConsulta.convenioId
                    ));
                this.buscarPeloPaciente =
                    agendamentoConsulta?.paciente.nomeCompleto;
                this.cpfPaciente = agendamentoConsulta?.paciente.cpf;

                this.agendamento.tipoAgendamento = agendamento.tipoAgendamento;
                await setTimeout(function () { }, 1);

                this.agendamento = {
                    ...agendamento,
                    agendamentoConsulta: agendamento.tipoAgendamento === TipoAgendamento.Compromisso ? new AgendamentoConsulta() : { ...agendamentoConsulta },
                    agendamentoCompromisso: agendamento.tipoAgendamento === TipoAgendamento.Consulta ? new AgendamentoCompromisso() : { ...agendamento.agendamentoCompromisso },
                };

                this.date = DateHelper.formataParaAnoMesDia(dataAgendamento);
                this.agendamento.dataAgendamento =
                    DateHelper.formataParaDiaMesAno(dataAgendamento);
                this.carregando = false;

            } catch (error) {
                AlertaSimplesErro('Aviso:', error);
                this.fecharModal();
            }
        },
        fecharModal() {
            this.horarios = [];
            this.agendamento = new Agendamento();
            this.preConsultaPaciente = false;
            this.modalAberto = false;
            this.carregando = false;
            this.editarAgendamento = false;
            this.cpfPaciente = '';
            this.limparCamposPreConsulta();
        },
        //#endregion

        //#region <Funções Paciente>
        filtrarPaciente(item, queryText, itemText) {
            return (
                item.nome
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.cpf
                    .replace(/\D/g, '')
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1

            );

        },
        async carregarDadosPaciente(pacienteId: string) {
            this.pacienteService
                .Get(pacienteId)
                .then((response) => {
                    const paciente = response.data;
                    if (paciente) {
                        this.agendamento.agendamentoConsulta.telefone =
                            paciente.contato.telefonePrincipal;
                        this.agendamento.agendamentoConsulta.email =
                            paciente.contato.emailPrincipal;
                        this.cpfPaciente = paciente.cpf;
                    }
                })
                .catch((error) => { });
        },
        async atualizarDadosPaciente() {
            if (this.agendamento.agendamentoConsulta.pacienteId != 0) {
                try {
                    await this.carregarDadosPaciente(
                        this.agendamento.agendamentoConsulta.pacienteId
                    );
                } catch (error) { }
            }
            if (this.editarAgendamento) {
                return;
            }
        },
        alteraVariavelNovoPaciente() {
            this.preConsultaPaciente = !this.preConsultaPaciente;
            if (this.preConsultaPaciente) {
                this.limparCamposPreConsulta();
            }
        },
        //#endregion

        //#region <Funções do componente de Situação do Agendamento>
        corDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
            return this.opcoesSituacaoAgendamento.find(
                (x) => x.value == situacaoAgendamento
            )!.color;
        },
        iconeDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
            return this.opcoesSituacaoAgendamento.find(
                (x) => x.value == situacaoAgendamento
            )!.icon;
        },
        textDaSituacaoAgendamento(situacaoAgendamento: SituacaoAgendamento) {
            return this.opcoesSituacaoAgendamento.find(
                (x) => x.value == situacaoAgendamento
            )!.text;
        },
        //#endregion

        //#region <Funções sobre Data>
        async dataEstaBloqueada(dataParaVerificar: string): Promise<boolean> {
            try {
                var dataParaVerificarTipada: Date = new Date(
                    dataParaVerificar + 'T00:00:00'
                );

                await this.buscarBloqueiosEFeriados();
                const bloqueioEncontrado = this.datasBloqueadas.find(
                    (periodo) =>
                        dataParaVerificarTipada >=
                        new Date(periodo.dataInicio) &&
                        dataParaVerificarTipada <= new Date(periodo.dataFim)
                );

                const feriadoEncontrado = this.feriados.find(
                    (feriado) =>
                        new Date(feriado.data).getDate() ==
                        dataParaVerificarTipada.getDate() &&
                        new Date(feriado.data).getMonth() ==
                        dataParaVerificarTipada.getMonth()
                );

                const diaSemana = dataParaVerificarTipada.getDay();
                if (!this.agenda.listaHorarios.find((x) => x.diaSemana === diaSemana)) {
                    MensagemMixin(
                        8000,
                        'error',
                        `Este dia não possui horário cadastrado!`
                    );
                    return true;
                }
                if (
                    !this.editarAgendamento &&
                    DateHelper.dataMenorQueHoje(dataParaVerificar)
                ) {
                    MensagemMixin(
                        8000,
                        'error',
                        `Não é possível cadastrar um agendamento com data passada!`
                    );
                    return true;
                } else if (bloqueioEncontrado) {
                    MensagemMixin(
                        8000,
                        'error',
                        `Existe um bloqueio para esta data!`
                    );
                    return true;
                } else if (feriadoEncontrado) {
                    MensagemMixin(
                        8000,
                        'error',
                        `O feriado está marcado para esta data!`
                    );
                    return true;
                } else {
                    return false;
                }
            } catch (error) { }
        },
        desabilitarDatas(dataParaVerificar: string): boolean {
            const data: Date = new Date(dataParaVerificar + 'T00:00:00');
            if (DateHelper.dataMenorQueHoje(dataParaVerificar)) return false;
            const diaSemana = data.getDay();
            if (!this.agenda.listaHorarios.find((x) => x.diaSemana === diaSemana)) {
                return false;
            }
            if (this.datasBloqueadas.some((periodo) =>
                data >= new Date(periodo.dataInicio) &&
                data <= new Date(periodo.dataFim)))
                return false;

            if (
                this.feriados.some(
                    (feriado) => feriado.data == dataParaVerificar + 'T00:00:00'
                )
            )
                return false;
            return true;
        },
        parseDate(date: string) {
            if (!date) return '';
            const [day, month, year] = date.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        async buscarProximaDataDisponivel() {
            var data = await this.agendamentoService
                .BuscarProximaDataDisponivel(this.idAgenda)
                .then(
                    (res) => {
                        return res.data;
                    },
                    (err) => {
                        AlertaSimplesErro('Aviso:', err);
                    }
                );

            return data;
        },
        //#endregion

        //#region <Funções Pré Consulta>
        limparCamposPreConsulta() {
            this.agendamento.agendamentoConsulta.telefone = '';
            this.agendamento.agendamentoConsulta.email = '';
            this.agendamento.agendamentoConsulta.pacienteId = '';
            this.agendamento.agendamentoConsulta.convenioId = null;
            this.agendamento.agendamentoConsulta.planoId = null;
            this.cpfPaciente = '';
        },
        //#endregion

        //#region <Funções sobre Convenio e Plano>
        async preencherConvenios() {
            await this.convenioService.getAllSelect().then((res) => {
                this.options_convenio = res.data;
            });
        },
        async preencherPlanos(convenioId: number) {
            this.options_plano = [];
            const res = await this.planoService.getAllSelect(convenioId);
            this.options_plano = res.data;
        },
        mudarPlanos() {
            this.agendamento.agendamentoConsulta.planoId = undefined;
            this.preencherPlanos(
                this.agendamento.agendamentoConsulta.convenioId
            );
        },
        //#endregion

        //#region <Funções sobre bloqueios, feriados, tipo de consulta, horário e duração do agendamento>
        async buscarBloqueiosEFeriados() {
            await this.bloqueiosHorariosService.Get(this.idAgenda).then(
                async (res) => {
                    if (res.data) {

                        this.datasBloqueadas = res.data;
                    }
                },
                (err) => { }
            );

            await this.feriadosService.Get(this.idAgenda).then(
                async (res) => {
                    if (res.data) {
                        this.feriados = res.data;
                    }
                },
                (err) => { }
            );
        },
        async preencherTipoConsulta(idAgenda: number) {
            await this.tipoConsultaService.getAllSelect(idAgenda).then(
                (res) => {
                    this.options_consulta = res.data;
                },
                (err) => { }
            );
        },
        mudarTipoAgendamento() {
            if (
                this.agendamento.tipoAgendamento == TipoAgendamento.Compromisso
            ) {
                this.limparCamposPreConsulta();
                this.agendamento.agendamentoConsulta =
                    new AgendamentoConsulta();
                this.agendamento.agendamentoConsulta.tipoConsultaId = undefined;
            } else
                this.agendamento.agendamentoCompromisso =
                    new AgendamentoCompromisso();
        },
        async buscarHorarios(idAgenda: number, data: string): Promise<Boolean> {
            this.horarios = [];
            var possuiHorarios = false;
            await this.agendamentoService.GetAgendamentoHorarios(
                idAgenda,
                DateHelper.formatarParaAnosMesDiaISO(data))
                .then(
                    (res) => {
                        this.horariosAgenda = res.data;
                        res.data.forEach((Listahorarios: { horarios: ConcatArray<never>; }) => {
                            this.horarios = this.horarios.concat(
                                Listahorarios.horarios
                            );
                        }
                        );
                        if (this.horariosAgenda.length > 0) {
                            possuiHorarios = true;
                            this.agendamento.horaAgendamento = this.horariosAgenda[0].horarios[0].value;
                            this.agendamento.duracaoAgendamento = this.horariosAgenda[0].duracaoAgendamento;
                        }
                    },
                    (err) => {
                        possuiHorarios = false;
                        this.agendamento.horaAgendamento = "";
                        this.agendamento.duracaoAgendamento = "";
                        AlertaSimplesErro('Aviso:', err);
                    }
                );
            return possuiHorarios;
        },
        preencherDuracaoAgendamento() {
            this.horariosAgenda.forEach((Listahorarios) => {
                if (
                    Listahorarios.horarios.find(
                        (hora: { value: string }) =>
                            hora.value == this.agendamento.horaAgendamento
                    )
                ) {
                    this.agendamento.duracaoAgendamento =
                        Listahorarios.duracaoAgendamento;
                }
            });
        },
        //#endregion

        //#region <Função Salvar>

        async salvar(): Promise<Boolean> {

            const dataAgendamentoBackup = this.agendamento.dataAgendamento;
            const dateBackup = this.date;

            if (
                this.agendamento.tipoAgendamento === TipoAgendamento.Consulta &&
                !this.preConsultaPaciente
            ) {
                if (
                    this.agendamento.agendamentoConsulta &&
                    !this.agendamento.agendamentoConsulta.pacienteId
                ) {
                    AlertaSimples(
                        'Atenção',
                        'Selecione um paciente para continuar.',
                        'warning'
                    );
                    return false;
                }
            }

            const valid = await (this.$refs.observer as any).validate();
            if (!valid) {
                return false;
            }

            try {
                if (
                    await this.dataEstaBloqueada(
                        DateHelper.formataParaAnoMesDia(this.date)
                    )
                )
                    return false;

                this.agendamento.dataAgendamento =
                    DateHelper.formataParaAnoMesDia(this.date);

                this.carregando = true;
                if (this.agendamentoRetorno) {
                    this.$emit('retorno', this.agendamento);
                    this.fecharModal();
                    return;
                }

                if (
                    this.agendamento.tipoAgendamento ===
                    TipoAgendamento.Consulta
                ) {
                    if (!this.preConsultaPaciente)
                        this.agendamento.agendamentoConsulta.paciente =
                            undefined;
                    this.agendamento.agendamentoCompromisso = undefined;
                } else if (
                    this.agendamento.tipoAgendamento ===
                    TipoAgendamento.Compromisso
                )
                    this.agendamento.agendamentoConsulta = undefined;

                if (this.preConsultaPaciente) {
                    this.agendamento.agendamentoConsulta.pacienteId = 0;
                    this.agendamento.agendamentoConsulta.paciente.telefonePrincipal =
                        this.agendamento.agendamentoConsulta.telefone;
                    this.agendamento.agendamentoConsulta.paciente.emailPrincipal =
                        this.agendamento.agendamentoConsulta.email;
                    this.agendamento.agendamentoConsulta.paciente.cpf =
                        this.cpfPaciente;
                }

                if (this.editarAgendamento) {
                    await this.agendamentoService.Put(
                        this.agendamento.id,
                        this.agendamento
                    ).then(
                        (res) => {
                            if (res.data.tipoAgendamento === TipoAgendamento.Consulta) {
                                this.idPaciente = res.data.agendamentoConsulta.pacienteId
                                this.idAgendamento = res.data.id
                            }
                        }
                    );
                } else {
                    await this.agendamentoService.Post(this.agendamento).then(
                        (res) => {
                            if (res.data.tipoAgendamento === TipoAgendamento.Consulta) {
                                this.idPaciente = res.data.agendamentoConsulta.pacienteId
                                this.idAgendamento = res.data.id
                            }
                        }
                    );
                }

                MensagemMixin(
                    3000,
                    'success',
                    this.editarAgendamento
                        ? 'Agendamento editado com sucesso.'
                        : 'Agendamento cadastrado com sucesso.'
                );

                this.fecharModal();

                this.$emit('atualizaCalendario');
                return true;
            } catch (error) {
                this.date = '';
                await setTimeout(function () { }, 1);
                this.date = dateBackup;
                AlertaSimplesErro('Aviso:', error);
                this.carregando = false;
                return false

            }
        },
        //#endregion

        //#region <Função Triagem>
        async realizarTriagemParaForm() {
            if (await this.salvar()) {
                this.modalTriagens = true;
                await setTimeout(function () { }, 1);
                await this.$refs.modalTriagem.carregarTriagemNoAgendamento(this.idAgendamento, this.idPaciente);

            }
        },


        fecharModalTriagem() {
            this.modalTriagens = false;

        },
        validarDataNascimentoParaDesabilitarTriagem() {
            const dataNascimento = this.agendamento.agendamentoConsulta.paciente.dataNascimento;
            if (dataNascimento) {
                this.dataNascimentoValido = true;
            } else {
                this.dataNascimentoValido = false;
            }
        },

        //#endregion

        desabilitaModulosComplementares() {
            const sessaoUsuario = decode(getToken());

            const temAcessoTriagem = sessaoUsuario.permissions.includes(PermissoesPadrao.AcessoTriagem);
            const temTriagemCreate = sessaoUsuario.permissions.includes(PermissoesPadrao.TriagemCreate);

            if (temAcessoTriagem && temTriagemCreate) {
                return false;
            }

            return true;
        },
    },
});
</script>

<style scoped>
.buttons-toggle {
    border-radius: 4px !important;
}
</style>
