import Agendamento from "../agendamento/Agendamento";
import Arquivo from "../arquivos/Arquivo";
import Cids from "../cids/Cids";
import { TipoConsultaRetorno } from "../enums/EnumTipoConsultaRetorno";
import Modelos from "../modelos/Modelos";

export default class Consulta {
    public id: number;
    public consultaRetorno?: TipoConsultaRetorno;
    public listaModelos: Modelos[] = [];
    public altura: number;
    public peso: number;
    public imc: number;
    public hipoteseDiagnostica: string;
    public listaCids: Cids[] = [];
    public retorno: string;
    public agendamentoRetornoId?: number;
    public agendamentoRetorno: Agendamento;
    public arquivosEmAnexo: Arquivo[] = [];
    public files: File[] = []


    constructor() {
        this.id = 0;
        this.listaModelos = [];
        this.altura = 0;
        this.peso = 0;
        this.imc = 0;
        this.hipoteseDiagnostica = "";
        this.listaCids = [];
        this.retorno = "";
        this.agendamentoRetornoId = 0;
        this.agendamentoRetorno = new Agendamento();
        this.arquivosEmAnexo = []
        this.files = []

    }
}
