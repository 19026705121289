import Agenda from "./Agenda";

export default class TipoConsulta{
    public id: number;
    public agendaId:number;
    public agenda: Agenda;
    public tenantId?:number;
    public cor:string;
    public descricao:string;


    constructor(){
        this.id= 0;
        this.agendaId = 0;
        this.agenda = new Agenda()
        this.tenantId= 0;
        this.cor= "";
        this.descricao= "";
    }

}