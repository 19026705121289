import Endereco from '@/models/objetosDeValor/Endereco';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Estado, Pais } from '@/models/Enums';
import { AlertaSimplesResponse } from '@/helpers/MensagemHelper';

export class EnderecoService {    

    private static readonly _url = 'https://api.postmon.com.br/v1/cep/';

    public async get(cep: string) {
        return new Promise<Endereco>((resolve, reject) => {
            axios.get(`${EnderecoService._url}/${cep}`)
                .then((response: AxiosResponse) => {
                    const address = new Endereco();
                    address.cep = response.data.cep;
                    address.logradouro = response.data.logradouro;
                    address.bairro = response.data.bairro;   
                    address.cidade = response.data.cidade;

                    const index = Estado.findIndex((estado) => estado.text === response.data.estado_info.nome);
                    if (index >= 0) {
                        address.estado = Number(Estado[index].value);                       
                        address.uf = Number(Estado[index].value);
                    }

                    address.pais = Pais.findIndex((pais) => pais.text === 'Brasil');

                    resolve(address);
                })
                .catch((error: AxiosError) => {
                    const err = error.response ? error.response.data : '';
                    reject(err);
                });
        });
    }    

}