import moment from "moment";
import Agenda from "./Agenda";

export default class FeriadoAgenda{
    public id:number;
    public tenantId?:number;
    public agendaId:number;
    public agenda:Agenda;
    public descricao:string;
    public data: string;

    constructor() {
        this.id = 0;
        this.agendaId = 0;
        this.agenda = new Agenda();
        this.descricao = "";
        this.data = "";
    }
}