import  http  from './ApiConfig';
import { Service } from './Service';
export class ConvenioService extends Service{

    constructor() {
        super('convenios');

    }

    public GetAllPaginado(limit: number, page: number, nome: string) {
        return http.get(`${this._nomeControle}?limit=${limit}&page=${page}&nome=${nome}`, this.GetHeader());
    }

    public getAllSelect() {        
        return http.get(`${this._nomeControle}/lista-convenios`, this.GetHeader());        
    }
}