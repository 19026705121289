<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :loading="carregando ? 'success' : null"
          no-data-text="Nenhum Horário encontrada(o)!"
          loading-text="Carregando os itens desta tabela! Aguarde..."
          :headers="colunas"
          :header-props="headerProps"
          :items="listaHorarios"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="botao-novo" cols="12" sm="12" md="12">
                <v-btn
                  depressed
                  color="success"
                  class="mb-2"
                  @click="abrirModalHorarios()"
                >
                  <v-icon>mdi-plus</v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.diaSemana="{ item }">
            {{ enumDiaSemana.find((x) => x.value == item.diaSemana)?.text }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              x-small
              fab
              color="success"
              @click="abrirModalHorariosParaEdicao(item)"
              title="Editar Horário"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              x-small
              fab
              color="error"
              @click="excluirHorario(item)"
              title="Excluir Horário"
            >
              <v-icon small>mdi-minus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="modalHorarios" max-width="700px">
              <v-card color="white">
              <v-card-title>
                <v-icon left color="success"> {{ iconeTituloHorarios }}</v-icon>
                {{ tituloHorarios }}
              </v-card-title>
              <validation-observer
                ref="observerHorarios"
                v-slot="{ }"
              >
                <form>
                  <v-card class="elevation-0">
                    <v-card-text class="pa-5">
                      <v-row>
                        <v-col cols="12" md="3" sm="12">
                          <soi-select
                            :label="'Dias'"
                            :items="enumDiaSemana"
                            v-model="horarioAgenda.diaSemana"
                            :nullable="false"
                          ></soi-select>
                        </v-col>
                        <v-col cols="12" md="3" sm="3">
                          <soi-input
                            type="time"
                            :name="'Início'"
                            v-model="horarioAgenda.horaInicio"
                            :label="'Início'"
                            :rules="{ required: true }"
                          ></soi-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="3">
                          <soi-input
                            type="time"
                            :name="'Fim'"
                            v-model="horarioAgenda.horaFim"
                            :label="'Fim'"
                            :rules="{ required: true }"
                          ></soi-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="3">
                          <soi-input
                            type="time"
                            :name="'Intervalo'"
                            v-model="horarioAgenda.intervalo"
                            :label="'Intervalo'"
                            :rules="{ required: true }"
                          ></soi-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="warning" @click="fecharModal()">
                        <v-icon left>mdi-keyboard-return</v-icon>
                        Voltar
                      </v-btn>
                      <v-btn
                        @click="salvar()"
                        color="success"
                        :loading="carregando" :disabled="carregando"
                      >
                        <v-icon left>mdi-checkbox-marked-circle</v-icon>
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </form>
              </validation-observer>
              </v-card>
            </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import HorarioAgenda from "../../models/agenda/HorarioAgenda";
import {
  AlertaExclusaoQuestaoBooleano,
  MensagemMixin,
} from "../../helpers/MensagemHelper";
import { DiaSemana } from "../../models/Enums";

export default Vue.extend({
  name: "soi-horarioAgenda",
  props: {
    value: {
      type: Array as () => HorarioAgenda[],
      required: true,
    },
  },
  computed: {
    tituloHorarios() {
      return this.editarHorarios ? "Editar Horarios" : "Criar Horarios";
    },
    iconeTituloHorarios() {
      return this.editarHorarios ? "mdi-clock-edit" : "mdi-clock-plus";
    },
  },
  watch: {
    value() {
      this.listaHorarios = this.value;
    },
  },
  data() {
    return {
      carregando: false,
      horarioAgenda: new HorarioAgenda(),
      listaHorarios: this.value,
      modalHorarios: false,
      editarHorarios: false,
      horarioAgendaEdicaoIndex: -1,
      enumDiaSemana: DiaSemana,
      headerProps: {
        sortByText: "Ordenar por",
      },
      colunas: [
        {
          text: "Dias",
          align: "center",
          value: "diaSemana",
          filterable: true,
          sortable: true,
        },
        {
          text: "Início",
          align: "center",
          value: "horaInicio",
          filterable: true,
          sortable: true,
        },
        {
          text: "Fim",
          align: "center",
          value: "horaFim",
          filterable: true,
          sortable: true,
        },
        {
          text: "Intervalo",
          align: "center",
          value: "intervalo",
          filterable: true,
        },
        {
          text: "Ações",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async salvar() {
      if (!this.ChecaHorario()) {
        await (this.$refs.observerBloqueios as any).reset();
        return;
      }

      const valid = await (this.$refs.observerHorarios as any).validate();
      if (!valid) {
        return;
      }
      if (this.editarHorarios) {
        if (this.horarioAgendaEdicaoIndex != -1)
          Object.assign(
            this.listaHorarios[this.horarioAgendaEdicaoIndex],
            this.horarioAgenda
          );
      } else {
        this.listaHorarios.push(this.horarioAgenda);
      }
      this.$emit("input", this.listaHorarios);
      this.editarHorarios = false;
      this.modalHorarios = false;
      this.horarioAgenda = new HorarioAgenda();
      await (this.$refs.observerHorarios as any).reset();
    },
    abrirModalHorarios() {
      this.modalHorarios = true;
      this.horarioAgenda = new HorarioAgenda();
    },
    async abrirModalHorariosParaEdicao(horario: HorarioAgenda) {
      this.horarioAgendaEdicaoIndex = this.listaHorarios.indexOf(horario);
      this.editarHorarios = true;
      this.modalHorarios = true;
      setTimeout(() => {
        this.horarioAgenda = Object.assign({}, horario);
      }, 10);
    },
    async excluirHorario(horario: HorarioAgenda) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.listaHorarios.indexOf(horario);
        this.listaHorarios.splice(index, 1);
        this.$emit("input", this.listaHorarios);
      }
    },
    ChecaHorario(): boolean {
      let valid = true;
      for (let i = 0; i < this.listaHorarios.length; i++) {
        for (let j = i + 1; j < this.listaHorarios.length; j++) {
          if (
            this.listaHorarios[i].diaSemana ===
              this.listaHorarios[j].diaSemana &&
            this.listaHorarios[i].horaInicio < this.listaHorarios[j].horaFim &&
            this.listaHorarios[i].horaInicio >= this.listaHorarios[j].horaInicio
          ) {
            valid = false;
            MensagemMixin(
              3000,
              "error",
              "Você está tentando cadastrar horarios que se sobrepõem em um mesmo dia!"
            );
            return valid;
          }
        }
      }
      if(this.horarioAgenda.horaInicio > this.horarioAgenda.horaFim){
        valid = false;
            MensagemMixin(
              3000,
              "error",
              "O horário inicial deve ser anterior ao horário final!"
            );
            return valid;
      }
      return valid;
    },
    async fecharModal() {
      this.modalHorarios = false;
      this.editarHorarios = false;
      await (this.$refs.observerHorarios as any).reset();
      this.horarioAgenda = new HorarioAgenda();
    },
  },
});
</script>
