import  http  from './ApiConfig';
import { Service } from './Service';

export class TipoConsultaService extends Service{

    constructor() {
        super('tipoConsulta');

    }

    public getAllSelect(agendaId: number) {        
        return http.get(`${this._nomeControle}/lista-tipo-consulta/${agendaId}`, this.GetHeader());        
    }
}