<template>
  <validation-provider v-slot="{ errors }" :name="name" :rules="rules">
    <v-select
      :id="name" :name="name"
      color="success"
      item-color="success"
      :items="items"
      :label="label"
      menu-props="auto"
      :error-messages="errors"
      :no-data-text="noDataText"
      dense
      outlined
      :disabled="disabled"
      v-model="selecionado"
      @change="enviar"
    >
    </v-select>
  </validation-provider>
</template>

<script lang="ts">
export default {
  name: "soi-select",
  props: {
    name: {
      type: String,
      default: "",
    },
    noDataText: {
      type: String,
      required: false,
      default: "Sem Registros",
    },
    label: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    nullable: {
      type: Boolean,
      required: false,
      default: true,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
  },
  data() {
    return {
      selecionado: "",
    };
  },
  watch: {
    value() {
      this.selecionado = this.value;
    },
    },
    mounted() {
        this.selecionado = this.value;
    },
  methods: {
    enviar() {
      this.$emit("input", this.selecionado);
    },
  },
};
</script>
