<template>
  <v-dialog v-model="modalEndereco" width="800" height="600">
    <v-card>
      <v-toolbar flat dark color="success">
        <v-toolbar-title>
          <v-icon>mdi mdi-map-marker</v-icon>
          Editar Endereço
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="fecharModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row class="form">
          <v-col cols="12" md="3">
            <soi-input :name="'CEP'" :label="'CEP'" v-model="endereco.cep" :placeholder="'00000-000'" :mask="masks.cep"
              v-on:input="getCep()"></soi-input>
          </v-col>
          <v-col cols="12" md="5">
            <soi-input :name="'Cidade'" :label="'Cidade'" v-model="endereco.cidade" :placeholder="'Cidade'"></soi-input>
          </v-col>
          <v-col cols="12" md="4">
            <soi-select :label="'Estado'" :items="enumEstado" v-model="endereco.estado">
            </soi-select>
          </v-col>
          <v-col cols="12" md="3">
            <soi-select :name="'Pais'" :label="'Pais'" :items="enumPais" v-model="endereco.pais">
            </soi-select>
          </v-col>
          <v-col cols="12" md="3">
            <soi-input :name="'Bairro'" :label="'Bairro'" v-model="endereco.bairro" :placeholder="'Bairro'">
            </soi-input>
          </v-col>
          <v-col cols="12" md="6">
            <soi-input :name="'Rua'" :label="'Rua'" v-model="endereco.logradouro" :placeholder="'Rua'"></soi-input>
          </v-col>
          <v-col cols="12" md="3">
            <soi-input :name="'Número'" :label="'Número'" v-model="endereco.numero" :placeholder="'Número'">
            </soi-input>
          </v-col>
          <v-col cols="12" md="3">
            <soi-select :name="'Tipo'" :label="'Tipo'" :items="enumTipoEndereco"
              v-model="endereco.tipoEndereco"></soi-select>
          </v-col>
          <v-col cols="12" md="6">
            <soi-input :name="'Complemento'" :label="'Complemento'" v-model="endereco.complemento"
              :placeholder="'Complemento'"></soi-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="fecharModal()" :loading="carregando">
          <v-icon>mdi-cancel</v-icon>Cancelar</v-btn>
        <v-btn color="success" class="mr-2" @click="salvar()" :loading="carregando" :disabled="carregando">
          <v-icon>mdi mdi-content-save-check</v-icon> Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Pacientes from '@/models/pacientes/Pacientes';
import { PacientesService } from '@/services/PacientesService';
import { Estado, SiglaUf, Pais, TipoEndereco } from '@/models/Enums';
import MaskHelper from '@/helpers/MaskHelper';
import { MensagemMixin } from '@/helpers/MensagemHelper';
import { EnderecoService } from '@/services/EnderecoService';
import Endereco from '@/models/objetosDeValor/Endereco';

export default Vue.extend({
  name: 'soi-modal-endereco',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalEndereco: false,
      carregando: false,
      paciente: new Pacientes(),
      endereco: new Endereco(),
      service: new PacientesService(),
      enderecoService: new EnderecoService(),
      responseOk: false,
      enumEstado: Estado,
      enumSiglaUf: SiglaUf,
      enumPais: Pais,
      enumTipoEndereco: TipoEndereco,
      masks: MaskHelper,
    }
  },
  watch: {
    value() {
      this.paciente = this.value;
    }
  },
  methods: {
    fecharModal() {
      this.modalEndereco = false;
    },

    abrirModal() {
      this.modalEndereco = true;
      setTimeout(() => {
        this.endereco = Object.assign({}, this.paciente.endereco);
      }, 10);
    },

    async salvar() {
      this.carregando = true;
      this.paciente.endereco = this.endereco;

      await this.service.Patch(this.paciente.id, this.paciente).then(
        (res) => {
          MensagemMixin(
            3000,
            "success",
            "Endereço do paciente editado com sucesso."
          );
          this.responseOk = true;
          this.fecharModal();
          this.carregando = false;

        },
        (err) => {
          this.carregando = false;
          MensagemMixin(3000, "error", err);
          this.responseOk = false;
        }
      );
    },

    async getCep() {
      if (this.endereco.cep.length == 9) {
        await this.enderecoService.get(this.endereco.cep).then((response) => {
          this.endereco.logradouro = response.logradouro;
          this.endereco.bairro = response.bairro;
          this.endereco.cidade = response.cidade;
          this.endereco.estado = response.estado;
          this.endereco.pais = response.pais;
        });
      }
    }
  }
});
</script>