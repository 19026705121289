import http from './ApiConfig';
import { Service } from './Service';

export class TriagemService extends Service{

    constructor() {
        super('triagem');
    }

    public async GetTriagemPorAgendamentoId(agendamentoId: number) {
        return http.get(`${this._nomeControle}/triagem-por-agendamento/${agendamentoId}`, this.GetHeader());
    }

    public async VerificaTriagemNoAgendamento(agendamentoId: number) {
        return http.get(`${this._nomeControle}/verifica-agendamento/${agendamentoId}`, this.GetHeader());
    }
}

