<template>
    <v-breadcrumbs :items="items" :divider="divider">
        <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href">                    
                    <span :style="item.href ? 'text-decoration: underline;' : ''" >
                        <v-icon size="20">{{ item.icon }}</v-icon>
                        {{ item.text }}
                    </span>
                </v-breadcrumbs-item>
            </template>     
    </v-breadcrumbs>
</template>

<script lang="ts">
import routes from '../router/sidebar';
export default {
    name: 'soi-breadcumb',   
    props: {
        divider: {
            type: String
        }
    },
    data() {
        return {            
            activeRoute: '',
            items: new Array
        }
    },
    created() {
        this.activeRoute = this.$route.path
        this.$router.afterEach((to, from) => {
            this.activeRoute = to.path
            this.updateBreadcrumbs()            
        })
        this.updateBreadcrumbs();  
    },
    methods: {
        updateBreadcrumbs() {            
            const currentRoute = routes.find(route => route.path === this.activeRoute);
            if (currentRoute) {
                this.items = [{
                    text: currentRoute.label,
                    to: currentRoute.path,
                    icon: currentRoute.icon
                }];            
            }       
        }
    }
}
</script>