<template>
    <router-link
    :class="{
      btn: variant !== 'link',
      [`btn-${variant}`]: true,
    }"
    :to="{ name: routeName, params: routeParams }"
    :disabled="disabled"
  >
    <span class="content">
      <v-icon v-if="!!icon" :icon="icon" size="sm" />
      <span :class="{ 'pl-2': !!icon }">{{ label }}</span>
    </span>
  </router-link>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.component('soi-link', {
    props: {
        variant: {
            type: String,
            default: '',
            required: false
        },
        routeName: {
            type: String,
            default: '',
            required: false
        },
        routeParams: {
            type: Object,
            default() {
                return {}
            },
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            default: '',
            required: false
        },
        label: {
            type: String,
            default: '',
            required: false
        },
    }
})
</script>
<style scoped>
.btn {
  min-width: 120px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>