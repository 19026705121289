import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"900"},model:{value:(_vm.modalAberto),callback:function ($$v) {_vm.modalAberto=$$v},expression:"modalAberto"}},[_c(VCard,{attrs:{"loading":_vm.realizandoRemocao ? 'success' : null,"disabled":_vm.realizandoRemocao}},[_c(VCardTitle,[_c(VIcon,{attrs:{"left":"","color":"success"}},[_vm._v("mdi-sofa-single")]),_vm._v("Sala de Espera - "+_vm._s(_vm.descricaoAgenda))],1),_c(VCardText,[_c(VDataTable,{attrs:{"loading":_vm.carregando ? 'success' : null,"show-select":"","single-select":"","no-data-text":"Nenhum paciente na sala de espera!","loading-text":"Buscando pacientes na sala de espera...","items":_vm.pacientesDaSalaDeEspera,"headers":_vm.colunas,"footer-props":{
            'itemsPerPageOptions':[3, 5, 10, 15],
            'itemsPerPageText': 'Pacientes por página',
            'pageText': '{0}-{1} de {2}'
        }},scopedSlots:_vm._u([{key:"item.espera",fn:function({ item }){return [_c('b',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.espera)+" ")])]}},{key:"item.agendamentoConsulta.horaChegada",fn:function({ item }){return [_c('a',[_vm._v(_vm._s(_vm.formataHora(item.agendamentoConsulta.horaChegada)))])]}}]),model:{value:(_vm.linhaSelecionada),callback:function ($$v) {_vm.linhaSelecionada=$$v},expression:"linhaSelecionada"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"warning"},on:{"click":function($event){return _vm.fecharModal()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-keyboard-return")]),_vm._v(" Voltar ")],1),_c(VBtn,{attrs:{"loading":_vm.realizandoRemocao,"color":"error","disabled":_vm.linhaSelecionada.length > 0 ? false : true},on:{"click":function($event){return _vm.abrirModalRemoverPaciente()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-minus")]),_vm._v(" Remover ")],1),(_vm.possuiPermissaoCriarAtendimento)?_c(VBtn,{attrs:{"disabled":_vm.linhaSelecionada.length > 0 ? false : true,"color":"primary"},on:{"click":function($event){return _vm.atender()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-stethoscope")]),_vm._v(" Atender ")],1):_vm._e()],1)],1)],1),_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.modalRemoverPaciente),callback:function ($$v) {_vm.modalRemoverPaciente=$$v},expression:"modalRemoverPaciente"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(`Remover ${_vm.agendamentoARemover.agendamentoConsulta.paciente.nomeCompleto} da sala de espera?`))]),_c(VCardText,[_c('h4',[_vm._v("Situação do agendamento:")]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"height":"40","block":"","outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":"","dense":"","color":_vm.corDaSituacaoAgendamento(
                                            _vm.situacaoAgendamento
                                        )}},[_vm._v(" "+_vm._s(_vm.iconeDaSituacaoAgendamento( _vm.situacaoAgendamento ))+" ")]),_c('span',{class:`${_vm.corDaSituacaoAgendamento(
                                                    _vm.situacaoAgendamento
                                                )}--text`},[_vm._v(" "+_vm._s(_vm.textDaSituacaoAgendamento( _vm.situacaoAgendamento )))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,_vm._l((_vm.opcoesSituacaoAgendamento),function(item){return _c(VListItem,{key:item.value,on:{"click":function($event){_vm.situacaoAgendamento =
                                        item.value}}},[_c(VListItemTitle,{attrs:{"text-color":item.color}},[_c(VIcon,{attrs:{"color":item.color,"left":"","dense":""}},[_vm._v(_vm._s(item.icon))]),_c('span',{class:`${item.color}--text`},[_vm._v(_vm._s(' ' + item.text))])],1)],1)}),1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){_vm.modalRemoverPaciente = false}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-keyboard-return")]),_vm._v(" Cancelar ")],1),_c(VBtn,{attrs:{"loading":_vm.realizandoRemocao,"color":"error"},on:{"click":function($event){return _vm.removerAgendamentoDaSalaDeEspera()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-minus")]),_vm._v(" Remover ")],1)],1)],1)],1),_c('ModalAtendimentosNaoFinalizados',{ref:"modalAtendimentosNaoFinalizados"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }