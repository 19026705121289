<template>
  <v-dialog persistent v-model="modalConfiguracao" transition="dialog-bottom-transition">
    <v-card :loading="carregando ? 'success' : null" :disabled="carregando" color="white">
      <v-toolbar>
        <v-toolbar-title><v-icon color="success" left>mdi-cog</v-icon> Configurações da Agenda</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelar()" color="error" :loading="carregando">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs show-arrows v-model="tabActive" active-class="v-window-item--active" slider-color="#2dcb73">
            <v-tab>
              <v-icon left>mdi-information</v-icon>
              Geral
            </v-tab>
            <v-tab v-show="permiteCadastroHorarios">
              <v-icon left>mdi-clock-time-eight</v-icon>
              Horários
            </v-tab>
            <v-tab v-show="permiteCadastroBloqueios">
              <v-icon left>mdi-timer-cancel</v-icon>
              Bloqueios
            </v-tab>
            <v-tab v-show="permiteCadastroFeriados">
              <v-icon left>mdi-beach</v-icon>
              Feriados
            </v-tab>
            <v-tab v-show="permiteCadastroTipoConsulta">
              <v-icon>mdi-text-box-plus</v-icon>
              Tipos de Consulta
            </v-tab>
            <v-tab v-show="permitirCrudUsuarioComAcesso">
              <v-icon left>mdi-account-group</v-icon>
              Usuários com Acesso
            </v-tab>
            <v-tab v-show="permiteCadastroAvisos">
              <v-icon left>mdi-message-cog</v-icon>
              Avisos
            </v-tab>
            <v-tab>
              <v-icon left>mdi-email-arrow-right</v-icon>
              Agendamento Online
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tabActive">
        <validation-observer ref="observer" v-slot="{}">
          <form @submit.prevent="salvar">
            <!-- GERAL -->
            <v-tab-item>
              <v-card-text class="mt-3">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <soi-input disabled :name="'Proprietário'" v-model="agenda.usuario.nomeCompleto"
                      :label="'Proprietário '" :rules="{ required: true }"></soi-input>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <soi-input :name="'Descrição'" :label="'Descrição '" v-model="agenda.descricao"
                      :placeholder="'Descrição'" :disabled="false" :rules="{ required: true }"></soi-input>
                  </v-col>
                  <v-col cols="12" md="3" sm="4">
                    <soi-select :label="'Visualização'" :items="enumTipoVisualizacao"
                      v-model="agenda.tipoVisualizacao"></soi-select>
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <soi-input type="number" v-model="agenda.quantidadeFaltasPermitidas" :name="'Faltas Permitidas'"
                      :label="'Faltas Permitidas '" :rules="{ required: true }"></soi-input>
                  </v-col>
                  <v-col cols="12" md="3" sm="4">
                    <soi-switch v-model="agenda.avisarPaciente" :name="'check-button'"
                      :label="'Aviso para pacientes'"></soi-switch>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <soi-switch v-model="agenda.criarAgendamentoPartirMiniAgenda" :name="'check-button'"
                      :label="'Criar agendamento pela Mini Agenda'"></soi-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>

            <!-- Horarios -->
            <v-tab-item>
              <v-card-text class="mt-2">
                <soiHorarioAgenda v-model="agenda.listaHorarios">
                </soiHorarioAgenda>
              </v-card-text>
            </v-tab-item>

            <!-- Bloqueios -->
            <v-tab-item>
              <v-card-text class="mt-3">
                <soiBloqueioAgenda v-model="agenda.listaBloqueios">
                </soiBloqueioAgenda>
              </v-card-text>
            </v-tab-item>

            <!-- Feriados -->
            <v-tab-item>
              <v-card-text class="mt-3">
                <soiFeriadoAgenda v-model="agenda.listaFeriados"></soiFeriadoAgenda>
              </v-card-text>
            </v-tab-item>

            <!-- Tipo Consulta -->
            <v-tab-item>
              <v-card-text class="mt-3">
                <soiTipoConsultaAgenda v-model="agenda.tipoConsultas"></soiTipoConsultaAgenda>
              </v-card-text>
            </v-tab-item>

            <!-- Usuarios com Acesso -->
            <v-tab-item>
              <v-card-text class="mt-3">
                <soiUsuarioPermissao v-model="agenda.agendasUsuarios"></soiUsuarioPermissao>
              </v-card-text>
            </v-tab-item>

            <!-- Avisos -->
            <v-tab-item>
              <v-card-text class="mt-3">
                <soiConfiguracaoAviso v-model="agenda.listaConfiguracaoAviso"></soiConfiguracaoAviso>
              </v-card-text>
            </v-tab-item>

                <!--Agendamento Online-->
            <v-tab-item eager>
              <v-card-text class="mt-3">
                <soi-agendamento-online :descricao="agenda.descricao" :idAgenda="agenda.id"></soi-agendamento-online>
              </v-card-text>
            </v-tab-item>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="fecharModal()" :loading="carregando">
                <v-icon left>mdi-keyboard-return</v-icon> Voltar
              </v-btn>
              <v-btn id="salvar" type="submit" color="success" :loading="carregando" :disabled="carregando">
                <v-icon left>mdi-checkbox-marked-circle</v-icon> Salvar
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import DateHelper from "../../helpers/DateHelper";
import {
  AlertaPerguntaSimOuNaoBooleano,
  AlertaSimplesErro,
  MensagemMixin,
} from "../../helpers/MensagemHelper";
import Agenda from "../../models/agenda/Agenda";
import ConfiguracaoAviso from "../../models/agenda/ConfiguracaoAviso";
import { TipoVisualizacao } from "../../models/Enums";
import { AgendaService } from "../../services/AgendaService";
import { UsuarioService } from "../../services/UsuarioService";
import Vue from "vue";
import soiHorarioAgenda from "./HorarioConfiguracao.vue";
import soiBloqueioAgenda from "./BloqueioConfiguracao.vue";
import soiFeriadoAgenda from "./FeriadoConfiguração.vue";
import soiTipoConsultaAgenda from "./TiposDeConsultaConfiguracao.vue";
import soiConfiguracaoAviso from "./ConfiguracaoAviso.vue";
import soiUsuarioPermissao from "./PermissaoAgenda.vue";
import soiAgendamentoOnline from "./AgendamentoOnline.vue";
import { decode, getToken } from "../../utils/Token";
export default Vue.component("soi-agenda-configuracao", {
  name: "AgendaConfiguracao",
  components: {
    soiHorarioAgenda,
    soiBloqueioAgenda,
    soiFeriadoAgenda,
    soiTipoConsultaAgenda,
    soiConfiguracaoAviso,
    soiUsuarioPermissao,
    soiAgendamentoOnline
  },
  data() {
    return {
      selected: null,
      carregando: false,
      responseOk: false,
      agenda: new Agenda(),
      editarConfiguracao: false,
      configuracaoAviso: new ConfiguracaoAviso(),
      serviceAgenda: new AgendaService(),
      tabActive: null,
      usuarioService: new UsuarioService(),
      carregandoBuscarUsuario: false,
      service: new AgendaService(),
      enumTipoVisualizacao: TipoVisualizacao,
      validGeral: true,
      idAgenda: parseInt(this.$route.params.id),
      modalHide: false,
      modalConfiguracao: false,
      agendaModalConfiguracao: new Agenda(),
      agendaSalvar: Agenda,
      valid: true,
    };
  },
  computed: {
    permitirCrudUsuarioComAcesso() {
      return decode(getToken()).id === this.agenda.usuarioId;
    },
    permiteCadastroHorarios() {
      var idToken = decode(getToken()).id;
      if (idToken === this.agenda.usuarioId)
        return true;
      else if (this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken)) {
        return this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken).permiteCadastroHorarios
      }
      else return false;
    },
    permiteCadastroFeriados() {
      var idToken = decode(getToken()).id;
      if (idToken === this.agenda.usuarioId)
        return true;
      else if (this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken)) {
        return this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken).permiteCadastroFeriados
      }
      else return false;
    },
    permiteCadastroBloqueios() {
      var idToken = decode(getToken()).id;
      if (idToken === this.agenda.usuarioId)
        return true;
      else if (this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken)) {
        return this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken).permiteCadastroBloqueios
      }
      else return false;
    },
    permiteCadastroTipoConsulta() {
      var idToken = decode(getToken()).id;
      if (idToken === this.agenda.usuarioId)
        return true;
      else if (this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken)) {
        return this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken).permiteCadastroTipoConsulta
      }
      else return false;
    },
    permiteCadastroAvisos() {
      var idToken = decode(getToken()).id;
      if (idToken === this.agenda.usuarioId)
        return true;
      else if (this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken)) {
        return this.agenda.agendasUsuarios.find(x => x.usuarioId === idToken).permiteCadastroAvisos
      }
      else return false;
    }
  },
  methods: {
    async abrirModalConfiguracao(idAgenda: number) {
      this.modalConfiguracao = true;
      this.agenda = new Agenda();
      this.carregando = true;
      await this.serviceAgenda.Get(idAgenda).then(
        (res) => {
          this.agenda = res.data;
          this.carregando = false;
          this.agenda.listaBloqueios.forEach((bloqueio) => {
            bloqueio.dataInicio = DateHelper.formataParaDiaMesAno(
              bloqueio.dataInicio
            );
            bloqueio.dataFim = DateHelper.formataParaDiaMesAno(
              bloqueio.dataFim
            );
          });

          this.agenda.listaFeriados.forEach((feriado) => {
            feriado.data = DateHelper.formataParaDiaMesAno(feriado.data);
          });
        },
        (err) => {
          this.carregando = false;
          AlertaSimplesErro("Aviso:", err);
        }
      );
    },
    cancelar() {
      this.fecharModal();
    },
    async salvar() {
      try {
        this.carregando = true;
        var agendaSalvar: Agenda = JSON.parse(JSON.stringify(this.agenda));
        agendaSalvar.listaBloqueios.forEach((bloqueio) => {
          bloqueio.dataInicio = DateHelper.formataParaAnoMesDiaISO8601(
            bloqueio.dataInicio
          );
          bloqueio.dataFim = DateHelper.formataParaAnoMesDiaISO8601(
            bloqueio.dataFim
          );
        });

        agendaSalvar.listaFeriados.forEach((feriado) => {
          feriado.data = DateHelper.formataParaAnoMesDiaISO8601(feriado.data);
        });

        await this.service.Put(agendaSalvar.id, agendaSalvar).then(
          (res) => {
            MensagemMixin(
              3000,
              "success",
              "Configurações de Agenda salvas com sucesso."
            );
            this.$emit("atualizaConfiguracao");
            this.responseOk = true;
          },
          (err) => {
            AlertaSimplesErro("Aviso:", err);
            this.responseOk = false;
          }
        );
        if (this.responseOk) {
          this.modalConfiguracao = false;
          this.$emit("atualizaAgenda");
        }
        this.carregando = false;
      } catch (error) {
        this.carregando = false;
        console.error(error);
      }
    },
    async fecharModal() {
      if (await AlertaPerguntaSimOuNaoBooleano("Se você sair, perderá seus dados não salvos.", 'Deseja sair?')) {
        this.modalConfiguracao = false;
      }
    },
  },
});
</script>
<style>
.v-window-item--active {
  color: #2dcb73 !important;
}

.botao-novo {
  display: flex;
  justify-content: end;
}

.colorDiv {
  height: 30px;
  width: 60px;
  border-color: grey;
  border-style: solid;
  border-width: 2px;
}

.theme--light.v-application {
  color: #ffffff;
}
</style>
