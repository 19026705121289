import  http  from './ApiConfig';
import { Service } from './Service';

export class UsuarioService extends Service{

    constructor() {
        super('usuarios');
    }

    public GetAllPaginado(nomeOuId: string, limit: number, page: number) {
        return http.get(`${this._nomeControle}?nomeOuId=${nomeOuId}&limit=${limit}&page=${page}`, this.GetHeader());
    }
    
    public MudarTema(){
        return http.put(`${this.GetNomeControle()}/MudarTema`, this.GetHeader());
    }

    public ListarUsuariosParaLog() {
        return http.get(`${this._nomeControle}/listar-usuarios`, this.GetHeader());
    }     
}