import { TipoVisualizacao } from "../enums/EnumTipoVisualizacao";
import UsuarioDTO from "../usuario/UsuarioDTO";
import AgendaUsuario from "./AgendaUsuario";
import BloqueioHorarioAgenda from "./BloqueioHorarioAgenda";
import ConfiguracaoAviso from "./ConfiguracaoAviso";
import FeriadoAgenda from "./FeriadoAgenda";
import HorarioAgenda from "./HorarioAgenda";
import TipoConsulta from "./TipoConsulta";
export default class Agenda {
    public id:number
    public tenantId?:number
    public usuarioId:string
    public usuario: UsuarioDTO;
    public descricao: string;
    public tipoVisualizacao?: TipoVisualizacao
    public quantidadeFaltasPermitidas: number;
    public avisarPaciente: boolean;
    public criarAgendamentoPartirMiniAgenda: boolean;
    public bloqueada: boolean;
    public listaConfiguracaoAviso: ConfiguracaoAviso[] = [];
    public listaHorarios : HorarioAgenda[] = []
    public listaBloqueios: BloqueioHorarioAgenda[] = []
    public listaFeriados: FeriadoAgenda[] = []
    public agendasUsuarios: AgendaUsuario[] = []
    public tipoConsultas: TipoConsulta[] = []

    constructor(){
        this.id = 0;
        this.tenantId = 0;
        this.usuarioId = "";
        this.usuario = new UsuarioDTO();
        this.descricao = "";
        this.quantidadeFaltasPermitidas = 0;
        this.tipoVisualizacao;
        this.avisarPaciente = true;
        this.criarAgendamentoPartirMiniAgenda = false;
        this.bloqueada = false;
        this.listaHorarios = [];
        this.listaBloqueios = [];
        this.listaFeriados = [];
        this.listaConfiguracaoAviso = [];
        this.agendasUsuarios = [];
        this.tipoConsultas = [];
    }
}
