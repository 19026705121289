export interface IPermission {
  code: string;
  name: string;
}

const permissions: IPermission[] = [
  { code: '0001', name: 'UsuarioReadAll' },
  { code: '0002', name: 'UsuarioRead' },
  { code: '0003', name: 'UsuarioCreate' },
  { code: '0004', name: 'UsuarioUpdate' },
  { code: '0005', name: 'UsuarioDelete' },
  
  { code: '0006', name: 'ProfileUpdate'},

  { code: '0010', name: 'ClinicaRead' },
  { code: '0011', name: 'ClinicaUpdate' },

  { code: '0020', name: 'PacienteReadAll' },
  { code: '0021', name: 'PacienteRead' },
  { code: '0022', name: 'PacienteCreate' },
  { code: '0023', name: 'PacienteUpdate' },
  { code: '0024', name: 'PacienteDelete' },

  { code: '0030', name: 'RoleReadAll' },
  { code: '0031', name: 'RoleRead' },
  { code: '0032', name: 'RoleCreate' },
  { code: '0033', name: 'RoleUpdate' },
  { code: '0034', name: 'RoleDelete' },

  { code: '0040', name: 'AgendaUpdate' },
  { code: '0041', name: 'AgendaCreate' },
  { code: '0042', name: 'AgendaRead' },
  { code: '0043', name: 'AgendaDelete' },
  { code: '0044', name: 'AgendaReadAll' },

  { code: '0051', name: 'AgendamentoRead' },
  { code: '0052', name: 'AgendamentoCreate' },
  { code: '0053', name: 'AgendamentoUpdate' },
  { code: '0054', name: 'AgendamentoDelete' },

  { code: '0060', name: 'ConvenioReadAll' },
  { code: '0061', name: 'ConvenioRead' },
  { code: '0062', name: 'ConvenioCreate' },
  { code: '0063', name: 'ConvenioUpdate' },
  { code: '0064', name: 'ConvenioDelete' },

  { code: '0070', name: 'TenantReadAll' },
  { code: '0071', name: 'TenantRead' },
  { code: '0072', name: 'TenantCreate' },
  { code: '0073', name: 'TenantUpdate' },
  { code: '0074', name: 'TenantDelete' },

  { code: '0080', name: 'SalaDeEsperaRead' },
  
  { code: '0081', name: 'AtendimentoReadAll' },
  { code: '0082', name: 'AtendimentoRead' },
  { code: '0083', name: 'AtendimentoCreate' },
  { code: '0084', name: 'AtendimentoUpdate' },
  { code: '0085', name: 'AtendimentoDelete' },
  
  { code: '0086', name: 'ModeloReadAll' },
  { code: '0087', name: 'ModeloRead' },
  { code: '0088', name: 'ModeloCreate' },
  { code: '0089', name: 'ModeloUpdate' },
  { code: '0090', name: 'ModeloDelete' }, 
  
  { code: '0091', name: 'AuditoriaReadAll' },
  { code: '0092', name: 'AuditoriaRead' },
  
  { code: '0100', name: 'AcessoTriagem' },
  { code: '0101', name: 'TriagemReadAll' },
  { code: '0102', name: 'TriagemRead' },
  { code: '0103', name: 'TriagemCreate' },
  { code: '0104', name: 'TriagemUpdate' },
  { code: '0105', name: 'TriagemDelete' },
  
  { code: '0106', name: 'ImpressoPersonalizadoReadAll'},
  { code: '0107', name: 'ImpressoPersonalizadoRead'},
  { code: '0108', name: 'ImpressoPersonalizadoCreate'},
  { code: '0109', name: 'ImpressoPersonalizadoUpdate'},
];

export default permissions;
