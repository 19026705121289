import Agenda from "./Agenda";

export default class BloqueioHorarioAgenda{
    public id:number;
    public tenantId?:number;
    public agendaId:number;
    public agenda:Agenda;
    public descricao:string;
    public dataInicio:string;
    public dataFim: string;

    constructor(){
        this.id = 0;
        this.tenantId = 0;
        this.agendaId = 0;
        this.agenda = new Agenda();
        this.descricao = "";
        this.dataInicio = "";
        this.dataFim = "";

    }
}