import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLogin)?_c(VNavigationDrawer,{attrs:{"app":"","id":"nav-border","width":"307"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{staticClass:"sair-btn",attrs:{"color":"error","block":"","outlined":"","title":"Sair do Sistema"},on:{"click":_vm.sair}},[_c(VIcon,[_vm._v("mdi-logout")]),_vm._v(" Sair do Sistema ")],1)],1)]},proxy:true}],null,false,3040096645),model:{value:(_vm.abrirSidebar),callback:function ($$v) {_vm.abrirSidebar=$$v},expression:"abrirSidebar"}},[_c(VListItem,[_c(VListItemAvatar,[_c('img',{attrs:{"src":require("../assets/images/photo.png")}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("MedSystemCloud")])],1)],1),_c(VDivider),_c(VList,{attrs:{"nav":""}},[_c(VListItemGroup,_vm._l((_vm.items),function(item){return _c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(_vm.verificaAcesso(item.roles, item.permissions)),expression:"verificaAcesso(item.roles, item.permissions)"}],key:item.name,class:{
          'success white--text': _vm.isActive(item),
          'text--disabled': _vm.desabilitaModulosComplementares(item.roles, item.permissions)
        },attrs:{"to":item.path,"id":"items-sidebar","inactive":_vm.desabilitaModulosComplementares(item.roles, item.permissions)}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VTooltip,{attrs:{"right":"","disabled":!_vm.desabilitaModulosComplementares(item.roles, item.permissions)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VListItemContent,_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_c(VListItemTitle,{staticClass:"custom-font-size"},[_vm._v(_vm._s(item.label))])],1)]}}],null,true)},[_c('div',{staticClass:"d-inline-flex pa-0"},[_c('span',[_vm._v(" Entre em contato pelo telefone "),_c('br'),_vm._v("0800 707 5900 ou fale com o seu"),_c('br'),_vm._v(" consultor comercial para adquirir"),_c('br'),_vm._v(" essa funcionalidade. ")])])])],1)}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }