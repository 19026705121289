<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :loading="carregando ? 'success' : null"
          no-data-text="Nenhuma Tipo Consulta encontrado(a)!"
          loading-text="Carregando os itens desta tabela! Aguarde..."
          :headers="colunasConsulta"
          :items="listaTipoConsulta"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="botao-novo" cols="12" sm="12" md="12">
                <v-btn
                  depressed
                  color="success"
                  class="mb-2"
                  @click="abrirModalTipoConsulta()"
                >
                  <v-icon>mdi-plus</v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.cor="{ item }">
            <div class="colorDiv" :style="{ 'background-color': item.cor }"></div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
                class="mx-2"
                x-small
                fab
                color="success"
                @click="abrirModalTipoConsultaEdicao(item)"
                title="Editar Tipo de Consulta"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                x-small
                fab
                color="error"
                @click="excluirTipoConsulta(item)"
                title="Excluir Tipo de Consulta"
              >
                <v-icon small>mdi-minus</v-icon>
              </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="modalConsultas"
      max-width="700px"
    >
    <v-card>
      <v-card-title>
        <v-icon left color="success"> {{ iconeTituloTipoConsulta }}</v-icon>{{ tituloTipoConsulta }}
      </v-card-title>
      <validation-observer
        ref="observerTiposConsultas"
        v-slot="{ }"
      >
        <v-form>
          <v-card class="elevation-0">
            <v-card-text class="pa-5">
              <v-row>
                <v-col cols="12" md="2" sm="2">
                  <soi-input
                    type="color"
                    :name="'Cor'"
                    v-model="tiposConsulta.cor"
                    :label="'Cor'"
                  ></soi-input>
                </v-col>
                <v-col cols="12" md="10" sm="10">
                  <soi-input
                    :name="'Descrição'"
                    v-model="tiposConsulta.descricao"
                    :label="'Descrição'"
                    :rules="{ required: true }"
                  ></soi-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="fecharModal()">
                <v-icon left>mdi-keyboard-return</v-icon>
                Voltar
              </v-btn>
              <v-btn
                @click="salvar()"
                color="success"
                :loading="carregando" :disabled="carregando"
              >
                <v-icon left>mdi-checkbox-marked-circle</v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { AlertaExclusaoQuestaoBooleano } from "../../helpers/MensagemHelper";
import TipoConsulta from "../../models/agenda/TipoConsulta";

export default Vue.extend({
  name: "soi-tipoConsultaAgenda",
  props: {
    value: {
      type: Array as () => TipoConsulta[],
      required: true,
    },
  },
  computed: {
    tituloTipoConsulta() {
      return this.editarTipoConsulta
        ? "Editar Tipo Consulta"
        : "Criar Tipo Consulta";
    },
    iconeTituloTipoConsulta() {
      return this.editarTipoConsulta ? "mdi-pencil" : "mdi-plus";

    },
  },
  watch: {
    value() {
      this.listaTipoConsulta = this.value;
    },
  },
  data() {
    return {
      carregando: false,
      tiposConsulta: new TipoConsulta(),
      listaTipoConsulta: this.value,
      modalConsultas: false,
      editarTipoConsulta: false,
      TipoConsultaEdicaoIndex: -1,
      colunasConsulta: [
        {
          text: "Cor",
          align: "center",
          value: "cor",
          filterable: true,
          sortable: true,
        },
        {
          value: "descricao",
          align: "center",
          text: "Descrição",
          filterable: true,
        },
        {
          text: "Ações",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    abrirModalTipoConsulta() {
      this.modalConsultas = true;
      this.tiposConsulta = new TipoConsulta();
    },
    async salvar() {
      const valid = await (this.$refs.observerTiposConsultas as any).validate();
      if (!valid) {
        return;
      }
      if (this.tiposConsulta.cor == "") {
        this.tiposConsulta.cor = "#000000";
      }
      if (this.editarTipoConsulta) {
        if (this.TipoConsultaEdicaoIndex != -1)
          Object.assign(
            this.listaTipoConsulta[this.TipoConsultaEdicaoIndex],
            this.tiposConsulta
          );
      } else {
        this.listaTipoConsulta.push(this.tiposConsulta);
      }
      this.$emit("input", this.listaTipoConsulta);
      this.editarTipoConsulta = false;
      this.modalConsultas = false;
      this.tiposConsulta = new TipoConsulta();
      await (this.$refs.observerTiposConsultas as any).reset();
    },
    async abrirModalTipoConsultaEdicao(consulta: TipoConsulta) {
      this.TipoConsultaEdicaoIndex = this.listaTipoConsulta.indexOf(consulta);
      this.editarTipoConsulta = true;
      this.modalConsultas = true;
      setTimeout(() => {
        this.tiposConsulta = Object.assign({}, consulta);
      }, 10);
    },
    async excluirTipoConsulta(consulta: TipoConsulta) {
      if (await AlertaExclusaoQuestaoBooleano()) {
        const index = this.listaTipoConsulta.indexOf(consulta);
        this.listaTipoConsulta.splice(index, 1);
        this.$emit("input", this.listaTipoConsulta);
      }
    },
    async fecharModal() {
      this.modalConsultas = false;
      this.editarTipoConsulta = false;
      await (this.$refs.observerTiposConsultas as any).reset();
      this.tiposConsulta = new TipoConsulta();
    },
  },
});
</script>
