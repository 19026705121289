import { TipoEndereco, Estado, SiglaUf, Pais } from "../enums/EnumEndereco";
export default class Endereco {
    public cep: string;
    public logradouro: string;
    public numero: string;
    public complemento: string;
    public bairro: string;
    public cidade: string;
    public estado?: Estado;
    public uf?: SiglaUf;
    public pais?: Pais;
    public tipoEndereco?: TipoEndereco;

    constructor() {
        this.cep = '';
        this.logradouro = '';
        this.numero = '';
        this.complemento = '';
        this.bairro = '';
        this.cidade = '';
    }
}

