import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mt-2 mb-2",attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Módulos Complementares")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","xs":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Recursos Adicionais")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5","sm":"12"}},[_c('soi-switch',{attrs:{"name":'Habilitar triagem',"label":_vm.pacoteAtivado.triagemAtiva ? 'Triagem Habilitada' : 'Habilitar triagem',"disabled":_vm.disabled},model:{value:(_vm.pacoteAtivado.triagemAtiva),callback:function ($$v) {_vm.$set(_vm.pacoteAtivado, "triagemAtiva", $$v)},expression:"pacoteAtivado.triagemAtiva"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }