import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AvatarInput from './components/form/AvatarInput.vue'
import FormSelect from './components/form/Select.vue'
import Switch from './components/form/Switch.vue'
import Radio from './components/form/Radio.vue'
import TextInput from './components/form/TextInput.vue'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetAlert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask'
import ModalAgendamento from './components/agendamento/ModalAgendamento.vue'
import ModalNotaDia from './components/agenda/ModalNotaDia.vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import Contato from './components/form/objetosDeValor/Contato.vue'
import Endereco from './components/form/objetosDeValor/Endereco.vue'
import ModalSalaDeEsperaVue from './components/agenda/ModalSalaDeEspera.vue'
import ModalBuscarAgendamento from './components/agendamento/ModalBuscarAgendamento.vue'
const ptBr = require('vee-validate/dist/locale/pt_BR.json');
import AgendaConfiguracao from './components/configuracaoAgenda/AgendaConfiguracao.vue'
import ModalPesquisarModelo from './components/modelo/ModalPesquisarModelo.vue'
import ModalAdicionarModelo from './components/modelo/ModalAdicionarModelo.vue'
import ModalPesquisarMedicamento from '@/components/medicamento/ModalPesquisarMedicamento.vue';
import ModalMedicamento from '@/components/medicamento/ModalMedicamento.vue';
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import vuetify from './plugins/vuetify'
import Calendario from './components/agenda/Calendario.vue'
import Uploader from './components/Uploader.vue'
import Link from './components/Link.vue';
import ModalEditarEndereco from './components/atendimento/ModalEndereco.vue';
import ModalEditarContato from './components/atendimento/ModalContato.vue';
import soiPadraoPaciente from './components/ObjetoPadrao/DadosPadraoPaciente.vue'
import soiPacienteConvenio from './components/ObjetoPadrao/DadosPadraoConvenio.vue'
import soiDadosPadraoTriagem from './components/ObjetoPadrao/DadosPadraoTriagem.vue';
import ModulosComplementares from './components/form/objetosDeValor/ModulosComplementares.vue';

import formPaciente from './views/paciente/formPacientes.vue';
import DashboardAgendamentos from './components/atendimento/DashboardAgendamentos.vue'
import Assistencia from './components/Assistencia.vue';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";

for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
  extend('cpf', {
    validate: value => {
      value = value.replace(/[^\d]+/g, '');
      if (value == '') return false;
      if (value.length != 11 ||
        value == "00000000000" ||
        value == "11111111111" ||
        value == "22222222222" ||
        value == "33333333333" ||
        value == "44444444444" ||
        value == "55555555555" ||
        value == "66666666666" ||
        value == "77777777777" ||
        value == "88888888888" ||
        value == "99999999999")
        return false;
      let add = 0;
      let rev = 0;
      for (let i = 0; i < 9; i++)
        add += parseInt(value.charAt(i)) * (10 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11)
        rev = 0;
      if (rev != parseInt(value.charAt(9)))
        return false;
      add = 0;
      for (let i = 0; i < 10; i++)
        add += parseInt(value.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11)
        rev = 0;
      if (rev != parseInt(value.charAt(10)))
        return false;
      return true;
    },
    message: 'O CPF informado é inválido'
  });
  extend('cnpj', {
  validate: value => {
    value = value.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (value.length !== 14) return false; // CNPJ deve ter 14 dígitos

    // Verifica se todos os dígitos são iguais, o que é inválido para CNPJ
    if (/^(\d)\1+$/.test(value)) return false;

    // Calcula o primeiro dígito verificador
    let length = value.length - 2;
    let numbers = value.substring(0, length);
    const digits = value.substring(length);
    let sum = 0;
    let pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result.toString() !== digits.charAt(0)) return false;

    // Calcula o segundo dígito verificador
    length++;
    numbers = value.substring(0, length);
    sum = 0;
    pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result.toString() !== digits.charAt(1)) return false;

    return true;
  },
  message: 'O CNPJ informado é inválido'
});
extend('password', {
  validate: (value) => {
    return /^(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,12}$/.test(value);
  },
  message: 'A senha deve ter entre 6 e 12 caracteres, conter pelo menos um símbolo especial e pelo menos uma letra maiúscula.',
});
extend('password_confirmation', {
  validate: (value, { target }: { target: string }) => {
    return value === target;
  },
  params: ['target'],
  message: (field, { target }) => {
    return `A confirmação de senha não corresponde à senha`;
  },
});
}

localize('pt_BR', ptBr);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueTheMask);
const sweetAlertOptions = {
  confirmButtonColor: 'var(--v-success-base)',
  cancelButtonColor: 'var(--v-success-base)',
  background: 'white',
  color: 'black',
  inputLabel: 'white'
};

Vue.use(VueSweetAlert2, sweetAlertOptions);

Vue.config.productionTip = false
Vue.component('soi-avatar-input', AvatarInput)
Vue.component('soi-select', FormSelect)
Vue.component('soi-input', TextInput)
Vue.component('soi-switch', Switch)
Vue.component('soi-radio', Radio)
Vue.component('soi-contato', Contato)
Vue.component('soi-endereco', Endereco)
Vue.component('soi-modulos-complementares',ModulosComplementares)
Vue.component('soi-sala-de-espera', ModalSalaDeEsperaVue)
Vue.component('soi-modal-agendamento', ModalAgendamento)
Vue.component('soi-modal-nota-dia', ModalNotaDia)
Vue.component('soi-modal-buscar-agendamento', ModalBuscarAgendamento)
Vue.component('soi-header', Header)
Vue.component('soi-sidebar', Sidebar)
Vue.component("soi-calendario", Calendario)
Vue.component('soi-pesquisar-modelo', ModalPesquisarModelo)
Vue.component('soi-adicionar-modelo', ModalAdicionarModelo)
Vue.component('soi-pesquisar-medicamento', ModalPesquisarMedicamento)
Vue.component('soi-medicamento', ModalMedicamento)
Vue.component("soi-agenda-configuracao", AgendaConfiguracao)
Vue.component("soi-modal-endereco", ModalEditarEndereco)
Vue.component("soi-modal-contato", ModalEditarContato)
Vue.component("soi-link", Link);
Vue.component("soi-dados-padrao-paciente",soiPadraoPaciente)
Vue.component("soi-dados-padrao-triagem", soiDadosPadraoTriagem)
Vue.component("soi-pacienteconvenio",soiPacienteConvenio)
Vue.component('soi-form-paciente', formPaciente)
Vue.component("soi-dashboard-agendamentos", DashboardAgendamentos)
Vue.component('soi-assistencia', Assistencia)



new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')