import { SituacaoAgendamento } from "../enums/EnumSituacaoAgendamento";

export default class AgendamentoCompromisso {
  
    public descricao: string;
  
    constructor() {
       
        this.descricao = '';
   
    }
}