import { Especialidade } from "../enums/EnumEspecialidade";
import { TipoPessoas } from "../enums/EnumTipoPessoa";

export default class DadosMedico{
    public crm:string;
    public especialidade?: Especialidade;
    public tipoPessoa? : TipoPessoas;
    public documento: string;

    constructor() {
        this.crm = '';
        this.especialidade;
        this.tipoPessoa;
        this.documento = '';
    }
}