import Agendamento from "../agendamento/Agendamento";
import Pacientes from "../pacientes/Pacientes";

export default class Triagem {

    public id: number;
    public statusTriagem: boolean;
    public pacienteId: number;
    public agendamentoId: number;
    public grauDor: number;
    public peso: number;
    public altura: number;
    public frequenciaCardiaca: number
    public frequenciaRespiratoria: number
    public pressaoArterial: number
    public temperatura: number
    public doencaCronica: string
    public sintomaPrincipal: string
    public atividadeFisica: boolean;
    public boaAlimentacao: boolean;
    public algumaMedicacao: string;
    public historicoFamiliar: boolean; // Booleana
    public historicoAlergia: string;


    constructor() {
        this.id = 0;
        this.statusTriagem = false;
        this.pacienteId = 0;
        this.agendamentoId = 0;
        this.grauDor = 0;
        this.peso = 0;
        this.altura = 0;
        this.frequenciaCardiaca = 0;
        this.frequenciaRespiratoria = 0;
        this.pressaoArterial = 0;
        this.temperatura = 0;
        this.doencaCronica = '';
        this.sintomaPrincipal = '';
        this.atividadeFisica = false;
        this.boaAlimentacao = false;
        this.algumaMedicacao = '';
        this.historicoFamiliar = false;
        this.historicoAlergia = ''
    }



}

